import React, { useEffect, useState } from "react";
import Breakpoint, { BreakpointProvider, setDefaultBreakpoints } from "react-socks";
import { Link } from '@reach/router';
import useOnclickOutside from "react-cool-onclickoutside";
import Parse from 'parse';
import Avatar from "@material-ui/core/Avatar";
import Web3 from 'web3';
import { useWeb3React } from '@web3-react/core'
import UserComponent from "../components/usercomponent";

setDefaultBreakpoints([
  { xs: 0 },
  { l: 1199 },
  { xl: 1200 }
]);

const NavLink = props => (
  <Link
    {...props}
    getProps={({ isCurrent }) => {
      // the object returned here is passed to the
      // anchor element's props
      return {
        className: isCurrent ? 'active' : 'non-active',
      };
    }}
  />
);

function avatarURL() {
  var avatar = Parse.User.current().get('avatar');
  if (avatar != null) {
    return Parse.User.current().get('avatar').url()
  } 
}

function handleLoggedInMenuSections() {
  if (Parse.User.current()) {
    return (
      <div>
      <span onClick={function () { handleLogout() }} className="btn-main lead">Log Out</span> <br /> <br />
    </div>
    )
  } else {
    return (
      <div className='navbar-item'>
      <NavLink to="/login">Login</NavLink>
    </div>
    )
  }
}

function handleLogout() {
  Parse.User.logOut();
  window.location.href = "/home";
}

export default function Header({active}) {

  //const { activate, deactivate, active, account, library, provider } = useWeb3React();

  function handleConnectWalletButtonSection() {
    if (showmenu) { return }
    if (typeof window.ethereum !== 'undefined') {
      if (window.ethereum.selectedAddress != null) {
        return (<div className='wallet-button'><a href="/dapp?web_register=true"><span className="inner-text">Disconnect</span></a></div>);
        //return ( <div> Connected as: {window.ethereum.selectedAddress} </div> );
      } else {
        return (<div><div className='wallet-button'><a href="/dapp?web_register=true"><span className="inner-text">Connect</span></a></div>
                <div className='download-button'><a href="http://app.veep.space/"><span className="inner-text">Download</span></a></div></div>
        );
      }
    } else {
      return (<div><div className='wallet-button'><a href="/dapp?web_register=true"><span className="inner-text">Connect</span></a></div>
      <div className='download-button'><a href="http://app.veep.space/"><span className="inner-text">Download</span></a></div></div>);
      
    }
  }

  function handleAvatarSection() {
    if (showmenu) { return }
    if (Parse.User.current()) {
      return (<div className='avatar-header'><div> 
        <a href={"/user?userId=" + Parse.User.current().get('username')} > <UserComponent avatarURL={avatarURL()} user={Parse.User.current()}/></a>
        </div></div>
      )
    }
  }
  
  const [openMenu, setOpenMenu] = React.useState(false);
  const [openMenu1, setOpenMenu1] = React.useState(false);
  const [openMenu2, setOpenMenu2] = React.useState(false);
  const [openMenu3, setOpenMenu3] = React.useState(false);
  const handleBtnClick = (): void => {
    setOpenMenu(!openMenu);
  };
  const handleBtnClick1 = (): void => {
    setOpenMenu1(!openMenu1);
  };
  const handleBtnClick2 = (): void => {
    setOpenMenu2(!openMenu2);
  };
  const handleBtnClick3 = (): void => {
    setOpenMenu3(!openMenu3);
  };
  const closeMenu = (): void => {
    setOpenMenu(false);
  };
  const closeMenu1 = (): void => {
    setOpenMenu1(false);
  };
  const closeMenu2 = (): void => {
    setOpenMenu2(false);
  };
  const closeMenu3 = (): void => {
    setOpenMenu3(false);
  };
  const ref = useOnclickOutside(() => {
    closeMenu();
  });
  const ref1 = useOnclickOutside(() => {
    closeMenu1();
  });
  const ref2 = useOnclickOutside(() => {
    closeMenu2();
  });
  const ref3 = useOnclickOutside(() => {
    closeMenu3();
  });

  const [showmenu, btn_icon] = useState(false);
  useEffect(() => {
    const headers = document.getElementById("myHeader");
    const totop = document.getElementById("scroll-to-top");
    const sticky = headers.offsetTop;
    const scrollCallBack = window.addEventListener("scroll", () => {
      btn_icon(false);
      if (window.pageYOffset > sticky) {
        headers.classList.add("sticky");
        totop.classList.add("show");

      } else {
        headers.classList.remove("sticky");
        totop.classList.remove("show");
      } if (window.pageYOffset > sticky) {
        closeMenu();
      }
    });
    return () => {
      window.removeEventListener("scroll", scrollCallBack);
    };
  }, []);

  return (
    <header id="myHeader" className='navbar white'>
      <div className='container'>
        <div className='row w-100-nav'>
          <div className='logo px-0'>
            <div className='navbar-title navbar-item'>
              <NavLink to="/">
                <img
                  src="./img/logo-2.png"
                  className="img-fluid d-block"
                  alt="#" width="160" height="80"
                />
                <img
                  src="./img/logo-2.png"
                  className="img-fluid d-3"
                  alt="#" width="160" height="80"
                />
                <img
                  src="./img/logo-2.png"
                  className="img-fluid d-none"
                  alt="#" width="160" height="80"
                />
              </NavLink>
            </div>
          </div>

          <BreakpointProvider>
            <Breakpoint l down>
              <div className="header-button-row">
              {handleAvatarSection()}
              {handleConnectWalletButtonSection()}
              <div className="search-icon" onClick={function () { window.location.href = "/search"}} >  <i className="fa fa-search fa-lg"></i>  </div>
              </div>
              {showmenu &&
              
                <div className='menu'>
                  <div className='navbar-item'>
                    <NavLink to="/home">Home</NavLink>
                  </div>

                  <div className='navbar-item'>
                    <NavLink to="/info">Features</NavLink>
                  </div>

                  <div className='navbar-item'>
                    <NavLink to="/top">Top Communities</NavLink>
                  </div>

                  <div className="spacer-half"></div>

                  {handleLoggedInMenuSections()}
                </div>
              }
            </Breakpoint>

            <Breakpoint xl>
              <div className='menu'>
                <div className='navbar-item'>
                  <NavLink to="/home">Home</NavLink>
                </div>

                <div className='navbar-item'>
                    <NavLink to="/info">Features</NavLink>
                  </div>
                
                <div className='navbar-item'>
                  <NavLink to="/top">Top Communities</NavLink>
                </div>
                    {handleLoggedInMenuSections()}
              </div>
            </Breakpoint>
          </BreakpointProvider>

          <div className='mainside'>
          </div>

        </div>

        <button className="nav-icon" onClick={() => btn_icon(!showmenu)}>
          <div className="menu-line white"></div>
          <div className="menu-line1 white"></div>
          <div className="menu-line2 white"></div>
        </button>

      </div>
    </header>
  );
}