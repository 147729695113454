import React from "react";

function Video({thumbUrl, videoUrl}) {

    const [isVideoLoaded, setIsVideoLoaded] = React.useState(false);
    
    const onLoadedData = () => {
        setIsVideoLoaded(true);
    };

return (
    <div className="video-container">
      <img
        src={thumbUrl}
        className="video-thumb tiny"
        alt="thumb"
        style={{ opacity: isVideoLoaded ? 0 : 1 }}
      />
      <video
        poster={thumbUrl}
        loop={true}
        muted={true}
        autoPlay={true}
        playsInline={1}
        muted={true}
        src={videoUrl}
        onLoadedData={onLoadedData}
        style={{ opacity: isVideoLoaded ? 1 : 0 }}
      />
    </div>
  );
};

export default Video;