import React, { useEffect, useState } from "react";
import Footer from '../components/footer';
import { createGlobalStyle } from 'styled-components';
import Parse from 'parse';
import PostComponent from "../components/postcomponent";
import { RequestPromise } from "request-promise";


const GlobalStyles = createGlobalStyle`
  .navbar {
    border-bottom: solid 1px rgba(255, 255, 255, .1) !important;
  }
  .comments-section {
    margin: 0 auto;
    max-width: 880px;
    padding: 20px;
  }
`;

// when loading this page, 
// need to passs contractAddresss & tokenId param
// OR nftId which is formatted string of (contractAddresss-tokenId)

export default function Asset() {
    const search = window.location.search;
    const params = new URLSearchParams(search);
    const contractAddress = params.get('contractAddress');
    const tokenId = params.get('tokenId');
    const nftId = params.get('nftId');
    
    
    function getpostId() {
        if (nftId) { return nftId }

        return contractAddress + "-" + tokenId 
    } 

    const [asset, setAsset] = useState([{},]);

    const [commentText, setCommentText] = useState([{}, ""]);
    const [posts, setPosts] = useState([{},]);

    const onScreenLoad = () => {
        fetchAsset().then(function (response) {
            setAsset(response);

            fetchPosts().then(function (response2) {
                setPosts(response2);
            })
        })
    }

    useEffect(() => {
        onScreenLoad();
    }, [])

    async function fetchAsset() {
        //if (!contractAddress || !tokenId) { return }
        var request = require('request-promise');

        var _contractAddress = contractAddress;
        var _tokenId = tokenId;

        if (nftId) {
            _contractAddress = nftId.split('-')[0]
            _tokenId = nftId.split('-')[1]
        }

        const options = {
            url: 'https://api.opensea.io/api/v1/assets?token_ids=' + _tokenId + '&asset_contract_address=' + String(_contractAddress),
            method: 'GET',
            headers: {
              'X-API-Key': '867399b4417343b89dd71871c8b624fd'
            }
        };

        const nameRequest = await request(options).then(function (nameResponse) {
            if (nameResponse != null) {
                const jsonObject = JSON.parse(nameResponse)
                return jsonObject
            } else {
                return "error"
            }
        }, function(error) {
            return "error"
        });

        return nameRequest
    }

    function handleAssetContentSection() {
        if (asset.assets != null) {
            return (<div className="post">
                <div className="nft-header">
                    {asset.assets[0].name} <br />
                </div>
                <br />
                <img className="nft-card" src={asset.assets[0].image_url} />
                <br />
                <div className="nft-description">{asset.assets[0].description}</div> <br /> <br />
            </div>)
        } else {
                return (<div>
                    <div className="spinner-container">
                        <div className="loading-spinner">
                        </div>
                    </div>
                </div>)
            }
    }

    //need to fix user url for address returned from opensea
    function handleAssetOwnedBySection() {
        if (asset.assets != null) {
            var userUrl = "/user?userId=" + asset.assets[0].owner.address
            var collectionUrl = "/community?id=" + asset.assets[0].collection.slug
            return (<div className="post">
                 <div className="nft-description">
                    <b>Owned By</b> <br />
                    <a href={userUrl}>@{<Address userAddress={asset.assets[0].owner.address} />}</a>
                    <br /> <br />
                    <b>Collection</b> <br />
                    <a href={collectionUrl}>@{asset.assets[0].asset_contract.name}</a>
                    <br /> <br />
                    <b>Contract</b> <br />
                    <a href={"https://etherscan.io/address/" + asset.assets[0].asset_contract.address }><Address userAddress={asset.assets[0].asset_contract.address} /></a> <br/>
                    Token Id: {asset.assets[0].token_id} <br /><br />
                    <b>Website</b> <br />
                    <a href={asset.assets[0].external_link}>{asset.assets[0].external_link}</a>
                    </div>  
                <br /> 
            </div>)
        } else {
            return (<div>
                <div className="spinner-container">
                    <div className="loading-spinner">
                    </div>
                </div>
            </div>)
        }
    }

    function Address({ userAddress }) {
        if (userAddress && userAddress.length > 0) {
          return (
            <span className="address">{userAddress.substring(0, 12)}…{userAddress.substring(userAddress.length - 4)}</span>
          );
        } else {
          return ( 'address-error')
        }
      }

    // Coments =======

    async function fetchPosts() {
        const postQuery = await Parse.Cloud.run("web_fetchNftPost", { "nftId": getpostId() }).then(function (response) {
            var responseJson = JSON.stringify(response)
            return JSON.parse(responseJson);
        }, function (error) {
            return "error"
        });
        return postQuery
    }

    function handleSubmitCommentSection() {
        if (Parse.User.current() != null) {
            return (
                <section className='jumbotro2 breadcumb no-bg'>
                <div className="timeline">
               <div className="post pb-5 mt-10">
                    <form className="actions">
                        <input type='text' id="new-posts-container-textfield" className="new-posts-container-textfield" placeholder="  Veep your reply" onChange={event => setCommentText(event.currentTarget.value)} />
                        <span onClick={function () { handleSubmitPostAction() }} className="btn-main lead ml-5 mt-2">Submit</span>
                    </form>
                </div>
                </div>
            </section>)
        } else {
            return (<div className="timeline"><div className="post"> <br />
            <span onClick={function () { window.location.href="/dapp?web_register=true" }} className="btn-main lead ml-3 mt-2 mb-3">Connect Wallet</span> <h3>  &nbsp; &nbsp; Login or connect your wallet to submit a comment.</h3> <br /></div></div>)
        }
    }

    async function handleSubmitPostAction() {
        const postQuery = await Parse.Cloud.run("submitTextPost", {
            "postContent": commentText,
            "includesNFT": false,
            "nftId": getpostId()
        }).then(function (response) {
            document.getElementById('new-posts-container-textfield').value = '';

            fetchPosts().then(function (response) {
                setPosts(response);
            })
        }, function (error) {
            alert("We encountered an error while submitting your post, please try again.")
        });
        return postQuery
    }

    function handlePostsSection() {
        if (posts && posts.length > 0 && posts.constructor === Array && posts[0].objectId != null) {
          return (<div> {posts.map((post) => (
            <PostComponent
              post={post}
              postId={post.objectId}
              username={post.username}
              PostContent={post.PostContent}
              image={post.postImageUrl}
              avatarURL={post.avatarURL}
              videoUrl={post.videoUrl}
              isAuthorVerified={post.isVerified}
              isAuthorENSVerified={post.isAuthorENSVerified}
              authorENS={post.ensDisplayName}
              isPostSigned={post.verifiedPost}
              communitySlug={post.CommunitySlug}
              nftImageUrl={post.nftImageUrl}
              collectionName={post.collectionName}
              token_id={post.tokenId}
              createdAt={post.createdAt}
              likeCount={post.Likes}
              postCreator={post.userPointer}
            />
          ))} </div>)
        } else {
            return (<div className="post"> <br />
            <span className="inline"><span aria-hidden="true" className="icon-chat ml-5"></span> <h3>  &nbsp; &nbsp; No comments yet.</h3></span> <br /></div>)
        }
      }

    // End comments =========

    return (
        <div>
            <GlobalStyles />
            <section className='jumbotro2 breadcumb no-bg mt-10'>
                <div className="timeline">
                  {handleAssetContentSection()}
                </div>
            </section>

            <section className='jumbotro2 breadcumb no-bg'>
                <div className="timeline">
                  {handleAssetOwnedBySection()}
                </div>
            </section>

         {handleSubmitCommentSection()}

            <section className='jumbotro2 breadcumb no-bg'>
                <div className="timeline">
                  {handlePostsSection()}
                </div>
            </section>

            <Footer />
        </div>
    );
}