import React, { useEffect, useState } from "react";
import Footer from '../components/footer';
import { createGlobalStyle } from 'styled-components';
import Parse from 'parse';
import Avatar from "@material-ui/core/Avatar";
import UserComponent from "../components/usercomponent";

const GlobalStyles = createGlobalStyle`
.navbar {
  border-bottom: solid 1px rgba(255, 255, 255, .1) !important;
}
`;

export default function Search() {

    const [users, setUsers] = useState([{},]);
    const [searchText, setSearchText] = useState([{}, ""]);

    const onScreenLoad = () => {
        fetchUsers().then(function (response) {
            setUsers(response);
        })
    }

    useEffect(() => {
        onScreenLoad();
    }, [])

    async function fetchUsers() {
        const postQuery = await Parse.Cloud.run("fetchRecentUserList", { "id": "1" }).then(function (response) {
            var responseJson = JSON.stringify(response)
            return JSON.parse(responseJson);
        }, function (error) {
        });
        return postQuery
    }

    async function handleSubmitPostAction() {
        if (searchText.length == 0) { alert("Type a username, wallet address, or .ENS name to search"); return }
        if (searchText.length > 50) { alert("Your search must be 50 characters or less."); return }

        const postQuery = await Parse.Cloud.run("searchUserByName", {
            "username": searchText
        }).then(function (response) {

            setSearchText("")
            document.getElementById('search-textfield').value = '';

            var responseJson = JSON.stringify(response)
            setUsers(JSON.parse(responseJson));
            return responseJson
        }, function (error) {
            alert("Search error.")
        });
        return postQuery
    }


    function searchFieldSection() {
        return (<div>
            <div className="timeline"><div className="post">
                <form className="actions">
                    <input type='text' id="search-textfield" className="search-textfield" placeholder="  Search @Username, .ENS, or Eth Address" onChange={event => setSearchText(event.currentTarget.value)} />
                    <div className="search-button"><span onClick={function () { handleSubmitPostAction() }} className="btn-main ml-5">Search</span></div>
                </form>
            </div> </div></div>)
    }
    

    function getUsernameDisplay(user) {
        if (user.ens != null) {
            return String(user.ens)
        } else {
            return user.username
        }
    }

    function handlePostsSection() {
        if (users && users.length >= 1) {
            if (users.constructor === Array) {
                if (!users[0].username) {
                    return (<div>  <div className="spinner-container">
                        <div className="loading-spinner">
                        </div>
                    </div></div>)
                }
                return (<div> {users.map((user) => (
                    <div className="post">  <div className="post__header">
                        <UserComponent avatarURL={user.avatarURL} user={user}/> 
                        <a href={"/user?userId=" + user.username}> @{getUsernameDisplay(user)} - Followers: {user.followerCount ? user.followerCount : 0} </a> 
                       
                        <br /> 
                    </div>
                     </div>
                ))} </div>)
            }
        }
    }

    return (
        <div>
            <GlobalStyles />
            <section className='jumbomain breadcumb no-bg mt-10'>
                {searchFieldSection()}
                <div className="timeline">
                    {handlePostsSection()}
                </div>
            </section>
            <Footer />
        </div>
    );
}