import React from 'react';
import Particle from '../components/Particle';
import SliderMainParticle from '../components/SliderMainParticle';
import Footer from '../components/footer';
import { createGlobalStyle } from 'styled-components';
import Roadmap1 from '../components/Roadmap';
import Clock from "../components/Clock";
import AppImgBox from '../components/AppImgBox';
import TeamBox from '../components/TeamBox';
import StepBox from '../components/StepBox';
import FeaturesBox from '../components/FeaturesBox';
import HomeHero from '../components/HomeHero';
import Reveal from 'react-awesome-reveal';
import { keyframes } from "@emotion/react";
import ReactGA from 'react-ga';

const GlobalStyles = createGlobalStyle`
  header#myHeader .logo .d-block{
    display: none !important;
  }
  header#myHeader .logo .d-none{
    display: block !important;
  }
  .navbar .mainside a{
    background: #6ccfa5;
    &:hover{
      box-shadow: 2px 2px 20px 0px #6ccfa5;
    }
  }
  .item-dropdown{
    .dropdown{
      a{
        &:hover{
          background: #6ccfa5;
        }
      }
    }
  }
  p.lead{
    color: #a2a2a2;
  }
  .navbar .navbar-item .lines{
    border-bottom: 2px solid #6ccfa5;
  }
  .jumbotron.no-bg{
    height: 100vh;
    overflow: hidden;
    background-repeat: repeat;
    background-size: cover;
    background-position: bottom;
    background-repeat: no-repeat;
  }
  #tsparticles{
    top: 0;
  }
  .text-uppercase.color{
    color: #6ccfa5;
  }
  .de_count h3 {
    font-size: 36px;
    margin-bottom: 0px;
  }
  .de_count h5{
    font-size: 14px;
    font-weight: 500;
  }
  h2 {
    font-size: 30px;
  }
  .box-url{
    text-align: center;
    h4{
      font-size: 16px;
    }
  }
  .de_countdown{
    border: solid 2px #6ccfa5;
  }
  .author_list_pp, .author_list_pp i, 
  .nft_coll_pp i, .feature-box.style-3 i, 
  footer.footer-light #form_subscribe #btn-subscribe i, 
  #scroll-to-top div{
    background: #de46e1;
  }
  footer.footer-light .subfooter .social-icons span i{
    background: #403f83;
  }
  .author_list_pp:hover img{
    box-shadow: 0px 0px 0px 2px #6ccfa5;
  }
  .nft__item_action span{
    color: #6ccfa5;
  }
  .feature-box.style-3 i.wm{
    color: rgba(222, 70, 225, 0.97);
  }
  @media only screen and (max-width: 1199px) {
    .navbar{
      
    }
    .navbar .menu-line, .navbar .menu-line1, .navbar .menu-line2{
      background: #fff;
    }
    .item-dropdown .dropdown a{
      color: #fff !important;
    }
  }
`;

const fadeInUp = keyframes`
  0% {
    opacity: 0;
    -webkit-transform: translateY(40px);
    transform: translateY(40px);
  }
  100% {
    opacity: 1;
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
`;
const fadeIn = keyframes`
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
`;


const eventTrack = (category, action, label) => {
  ReactGA.event({
    category: category,
    action: action,
    label: label,
  })
}
const Contact = () => (
  <div>
    <GlobalStyles />
    <section className="jumbotron no-bg" style={{ backgroundImage: `url(${'./img/background/VeepCityBG.png'})` }}>
      <Particle />
      <div className='col-lg-12 text-center'>
        <div class="jumbotron jumbotron-fluid">
          <div class="container mt-10 pt-0">
          <h1 class="header2">Contact Us</h1>
            <h3 class="header1-top">Veep.AR - The Most Advanced Augmented Reality</h3>
            <img src="./img/Veepman13.png"/>
          </div>
        </div>
      </div>
    </section>

    <Reveal className='onStep' keyframes={fadeInUp} delay={300} duration={900} triggerOnce>
      <section className='container'>
      <center>
          <div class="max-w-2xl mx-auto">
            <div
              class="p-4 w-full text-center bg-green-400 rounded-lg shadow-md sm:p-8">
              <h3 class="mb-2 text-3xl font-bold text-purple-700">🎉 Get ahold of us 🎉 </h3>
              <p class="text-base text-purple-700 sm:text-lg">Got questions or feedback? We'd love to hear from you.</p>
            </div>
          </div>
          <br />
        </center>
      </section>
    </Reveal>

    <Reveal className='onStep' keyframes={fadeInUp} delay={300} duration={900} triggerOnce>
      <center>
          <div class="max-w-2xl mx-auto">
            <div
              class="p-4 w-full text-center bg-purple-800 rounded-lg shadow-md sm:p-8"><center>
              <span onClick={() => window.open("mailto:contact@veepapp.org", "_self")} className="btn-main lead">Send us an email</span> <br />
            <span onClick={() => window.open("https://discord.gg/73YhhknZWK", "_self")} className="btn-main lead">Join Our Discord</span> </center>
            </div>
          </div>
          <br />
        </center>
    </Reveal>

    <Footer />
  </div>
);
export default Contact;