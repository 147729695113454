import React from 'react';
import Footer from '../components/footer';
import { Link } from '@reach/router';
import { Accordion } from 'react-bootstrap';
import FeaturesBox from '../components/FAQBox';
import { createGlobalStyle } from 'styled-components';
import FAQSection from '../components/FAQBox';

const GlobalStyles = createGlobalStyle`
  .navbar {
    border-bottom: solid 1px rgba(255, 255, 255, .1) !important;
  }
`;

const FAQ = () => (
<div>
<GlobalStyles/>

  <section className='jumbotron breadcumb no-bg'>
    <div className='mainbreadcumb'>
      <div className='container'>
        <div className='row'>
              <FAQSection />
        </div>
      </div>
    </div>
  </section>

  <Footer />
</div>

);
export default FAQ;