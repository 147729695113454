import React from 'react';
import Reveal from 'react-awesome-reveal';
import { keyframes } from "@emotion/react";

const fadeInUp = keyframes`
  0% {
    opacity: 0;
    -webkit-transform: translateY(40px);
    transform: translateY(40px);
  }
  100% {
    opacity: 1;
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
`;
const fadeIn = keyframes`
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
`;


const FAQSection = () => (
    <Reveal className='onStep' keyframes={fadeInUp} delay={600} duration={900} triggerOnce>
        <section class="relative py-16 min-w-screen animation-fade animation-delay">
            <div class="container px-0 px-8 mx-auto sm:px-12 xl:px-5">
                <div class="soraFont">
                    <p class="text-xs font-bold text-left text-purple-500 uppercase sm:mx-6 sm:text-center sm:text-normal sm:font-bold">
                        Got a Question? We’ve got answers.
                    </p>
                    <h3 class="mt-1 text-2xl font-bold text-left text-white-800 sm:mx-6 sm:text-3xl md:text-4xl lg:text-5xl sm:text-center sm:mx-0">
                        Frequently Asked Questions
                    </h3>
                    <div class="w-full px-6 py-6 mx-auto mt-10 bg-gray-700 border border-gray-200 rounded-lg sm:px-8 md:px-12 sm:py-8 sm:shadow lg:w-5/6 xl:w-2/3">
                        <h3 class="text-lg font-bold text-green-500 sm:text-xl md:text-2xl">What is Veep?</h3>
                        <p class="mt-2 text-base text-gray-600 sm:text-lg md:text-normal">
                            Veep is an augmented reality social network app slated for private access in late 2021.  Veep will offer the ability to create virtual spaces consisiting of virtual objects (such as NFT's) at private or public locations. We plan to push the limits of augmented reality, and for the first time users will be able to enjoy truely social aspects of AR.  Veep also has traditional social media aspects, and unique features such as displaying your NFT's on your profile.
                        </p>
                    </div>
                    <div class="w-full px-6 py-6 mx-auto mt-10 bg-gray-700 border border-gray-200 rounded-lg sm:px-8 md:px-12 sm:py-8 sm:shadow lg:w-5/6 xl:w-2/3">
                        <h3 class="text-lg font-bold text-green-500 sm:text-xl md:text-2xl">Features?</h3>
                        <p class="mt-2 text-base text-gray-600 sm:text-lg md:text-normal">
                            - Create and share Virtual Spaces <br />
                            - Private Spaces <br />
                            - Public Spaces (Locations) <br />
                            - Social Profiles <br />
                            - Feed, Timeline <br />
                            - AR Face Filters
                        </p>
                    </div>
                    <div class="w-full px-6 py-6 mx-auto mt-10 bg-gray-700 border border-gray-200 rounded-lg sm:px-8 md:px-12 sm:py-8 sm:shadow lg:w-5/6 xl:w-2/3">
                        <h3 class="text-lg font-bold text-green-500 sm:text-xl md:text-2xl">What are Virtual Spaces?</h3>
                        <p class="mt-2 text-base text-gray-600 sm:text-lg md:text-normal">
                            Virtual spaces are collections of augmeneted objects added to your environment.  Objects can be high definition 3d renders of real world objects, or even NFT's of various forms.
                            Once objects are added and positioned, you may save the scene as a private or public Space.
                        </p>
                    </div>
                    <div class="w-full px-6 py-6 mx-auto mt-10 bg-gray-700 border border-gray-200 rounded-lg sm:px-8 md:px-12 sm:py-8 sm:shadow lg:w-5/6 xl:w-2/3">
                        <h3 class="text-lg font-bold text-green-500 sm:text-xl md:text-2xl">What's the difference between a Public Space vs a Personal Space?</h3>
                        <p class="mt-2 text-base text-gray-600 sm:text-lg md:text-normal">
                            For Personal Spaces, the position of objects within the Space are <b>not</b> tied to any real world location.
                            The positions of objects are saved relative to each other, and the Personal Space can be opened from any location.
                            <br /> <br />
                            For Public Spaces, the position of objects <i>are</i> tied to a real world location.
                            For instance, a Public Space created at Times Square in NYC could consist of multiple objects added by anyone who has visited.
                            A Public Space can only be seen or launched by users who are in the specific area of the Space.
                        </p>
                    </div>
                    <div class="w-full px-6 py-6 mx-auto mt-10 bg-gray-700 border border-gray-200 rounded-lg sm:px-8 md:px-12 sm:py-8 sm:shadow lg:w-5/6 xl:w-2/3">
                        <h3 class="text-lg font-bold text-green-500 sm:text-xl md:text-2xl">Is Veep available for everyone?</h3>
                        <p class="mt-2 text-base text-gray-600 sm:text-lg md:text-normal">
                            Veep will definitely be available to everyone when the platform is ready.  Until the time is right, we would like to offer early access to the platform via the use of our NFT access tokens.
                        </p>
                    </div>
                    <div class="w-full px-6 py-6 mx-auto mt-10 bg-gray-700 border border-gray-200 rounded-lg sm:px-8 md:px-12 sm:py-8 sm:shadow lg:w-5/6 xl:w-2/3">
                        <h3 class="text-lg font-bold text-green-500 sm:text-xl md:text-2xl">When will access tokens be available for minting?</h3>
                        <p class="mt-2 text-base text-gray-600 sm:text-lg md:text-normal">
                            The tentative release date for minting our access token is currently TBA in December.
                        </p>
                    </div>
                    <div class="w-full px-6 py-6 mx-auto mt-10 bg-gray-700 border border-gray-200 rounded-lg sm:px-8 md:px-12 sm:py-8 sm:shadow lg:w-5/6 xl:w-2/3">
                        <h3 class="text-lg font-bold text-green-500 sm:text-xl md:text-2xl">Isn't your roadmap a bit ambitious?</h3>
                        <p class="mt-2 text-base text-gray-600 sm:text-lg md:text-normal">
                            That's the point.  As the metaverse becomes more of a reality rather than a dream - we want to be pioneers of this new land.  An augmented or mixed reality social experience opens new doors on the metaverse horizon that were previously unreachable without a VR headset or wearable device.  We will provide access to the metaverse in a matter of months - rather than years.
                        </p>
                    </div>
                </div>
            </div>
        </section>
    </ Reveal>

);

export default FAQSection;
