import React from 'react';
import { Link } from '@reach/router';
import CookieConsent from "react-cookie-consent";

const footer = () => (
    <footer className="footer-light">
        <div className="container">
            <CookieConsent
                location="bottom"
                buttonText="I understand"
                cookieName="VeepCookie1"
                style={{ background: "rgba(222, 70, 225, 0.97)" }}
                buttonStyle={{ background: "#6CF49A", color: 'white', fontSize: "13px" }}
                expires={150}
            >This website uses cookies for analytics purposes.</CookieConsent>
            <div className="row">
                <div className="col-md-3 col-sm-6 col-xs-1">
                    <div className="widget">
                        <img class="float-center"
                            src="./img/VeepBadge.png"
                            alt="#" height="175" width="175" />
                    </div>
                </div>
                <div className="col-md-3 col-sm-6 col-xs-1">
                    <div className="widget">
                        <h5>Product</h5>
                        <ul>
                            <li><a href="/info">Learn More</a></li>
                            <li><a href="http://app.veep.space">Download App</a></li>
                            <li><a href="https://mailchi.mp/828a5aa46d0f/veep">Newsletter</a></li>
                        </ul>
                    </div>
                </div>
                <div className="col-md-3 col-sm-6 col-xs-1">
                    <div className="widget">
                        <h5>Company</h5>
                        <ul>
                            <li><Link to="/contact">Contact</Link></li>
                        </ul>
                    </div>
                </div>
                <div className="col-md-3 col-sm-6 col-xs-1">
                    <div className="widget">
                    <h5>Legal</h5>  
                        <ul>
                        <li><Link to="/terms">Terms of Service</Link></li>
                            <li><Link to="/privacy">Privacy Policy</Link></li>
                        </ul>

                       
                    </div>
                </div>
            </div>
        </div>
        <div className="subfooter">
            <div className="container">
                <div className="row">
                    <div className="col-md-12">
                        <div className="de-flex">
                            <div className="de-flex-col">
                                <span onClick={() => window.open("", "_self")}>
                                    <span className="copy">&copy; Veep.AR&trade; Veep Social LLC 2015 - 2022</span>
                                </span>
                            </div>
                            <div className="de-flex-col">
                                <div className="social-icons">
                                    <span onClick={() => window.open("https://www.twitter.com/veepsocial", "_self")}><i className="fa fa-twitter fa-lg"></i></span>
                                    <span onClick={() => window.open("https://www.instagram.com/veepofficial/", "_self")}><i className="fa fa-instagram fa-lg"></i></span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </footer>
);
export default footer;
