import React, { useEffect, useState } from "react";
import Footer from '../components/footer';
import { createGlobalStyle } from 'styled-components';
import Parse from 'parse';

const GlobalStyles = createGlobalStyle`
  .navbar {
    border-bottom: solid 1px rgba(255, 255, 255, .1) !important;
  }
  .timeline {
    margin: 0 auto;
    max-width: 880px;
    padding: 20px;
  }
`;

export default function SubmitCommunity() {
    const search = window.location.search;
    const params = new URLSearchParams(search);
    const [user, setUser] = useState([{},]);
 
    const [name, setName] = useState([{},]);
    const [contractAddress, setContractAddress] = useState([{},]);

    const onScreenLoad = () => {
       
    }

    useEffect(() => {
        onScreenLoad();
    }, [])

    function handleUserInfoSections() {
        var curUser = Parse.User.current() 
        if (curUser != null) {
            return (<div>
                    <form className="actions">
                        <h2> Submit New Community</h2>
                        <input type='text' id="new-posts-container-textfield1" className="new-posts-container-textfield" placeholder="(Community Name)" onChange={event => setName(event.currentTarget.value)} />
                        <input type='text' id="new-posts-container-textfield2" className="new-posts-container-textfield" placeholder="(Contract Address)" onChange={event => setContractAddress(event.currentTarget.value)} />
                        <br />
                        <br /><br />
                        <span onClick={function () { doSaveAction() }} className="btn-main lead mt-2">Submit</span>
                        <br /> <br /> <br />
                        <i>Your submission will be pending until approved by an admin, normally within 24-48 hours.  We are currently only supporting Ethereum communities, but we are working to support other chains.</i> <br /> <br />
                    </form> 
            </div>)
        } else {
            return (<div className="timeline"><div className="post"> <br />
                <span onClick={function () { window.location.href = "/dapp?web_register=true" }} className="btn-main lead ml-5 mt-2 mb-5">Connect Wallet</span> <h3>  &nbsp; Login or connect your wallet to submit a new community.</h3> <br /></div></div>)
        }
    }

    async function doSaveAction() {
        if (typeof name !== 'undefined' && typeof contractAddress !== 'undefined') {
            await Parse.Cloud.run("web_submitCommunity", { "contractAddress": contractAddress, "name": name }).then(function (response) {
                var responseJson = JSON.stringify(response)
                alert("Your submission has been received. Your submission will be pending until approved by an admin, normally within 24-48 hours.")
                document.getElementById('new-posts-container-textfield1').value = '';
                document.getElementById('new-posts-container-textfield2').value = '';
                return JSON.parse(responseJson);

            }, function (error) {
                alert("We encountered an error during your submission.  Please try again.")
            });
        } else {
            alert("Please fill out all of the fields before pressing submit.")
        }
    }

    return (
        <div>
            <GlobalStyles />
            <div className='jumbotro2 pt-10 mt-10'> <div className="timeline"> 
                {handleUserInfoSections()}       
            </div></div>
            <Footer />
        </div>
    );
}