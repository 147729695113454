import React, { useEffect, useState } from "react";
import Footer from '../components/footer';
import PostComponent from "../components/postcomponent";
import NFTComponent from "../components/nftcomponent";
import { createGlobalStyle } from 'styled-components';
import Parse from 'parse';
import FeedComponent from "../components/feedcomponent";
import RecentUsersComponent from "../components/recentuserscomponent";


const GlobalStyles = createGlobalStyle`
.navbar {
  border-bottom: solid 1px rgba(255, 255, 255, .1) !important;
}
`;

export default function Home() {
    const [loading, setLoading] = useState([{},]);
    const [posts, setPosts] = useState([{},]);
    const [followingPosts, setFollowingPosts] = useState([{},]);
    const [postText, setPostText] = useState([{}, ""]);

    const [ownedNft, setOwnedNft] = useState([{}, ""]);
    const [selectedNFT, setSelectedNFT] = useState([{}, ""]);

    const [showNFT, setShowNFT] = React.useState(false)
    const onSelectNFTClick = () => setShowNFT(!showNFT)

    const onScreenLoad = () => {
      setPosts(null);
      setFollowingPosts(null);
      setLoading(false);
      setBase64IMG(null)

      //fetch trending posts from backend for even non-logged in users
      fetchPosts().then(function (response) {
        setPosts(response);

        //if user is logged in, fetch posts for this user (following posts)
        fetchFollowingPosts().then(function (response2) {
          if (response2 != null) {
            //merge following posts with trending posts
            setFollowingPosts(response2);
            mergeFollowingPosts(response, response2)
          }
        })

        handleFetchOwnedNFT() //only need to fetch owned NFT if logged in
      })
    }

    useEffect(() => {
        onScreenLoad();
    }, [])

 
  //helper function to merge array but remove duplicate items
  function uniqBy(a, key) {
    var seen = posts
    return a.filter(function (item) {
      var k = key(item);
      return seen.hasOwnProperty(k) ? false : (seen[k] = true);
    })
  }

  function mergeFollowingPosts(response, response2) {
    var responseR = JSON.parse(JSON.stringify(response2));
    const output = [].concat(response, responseR)

    var uniq = uniqBy(output, JSON.stringify)
    setPosts(uniq)
  }

  //fetch curated posts from backend
  async function fetchPosts(offset) {
    const postQuery = await Parse.Cloud.run("web_fetchTimeline", { "id": "1" }).then(function (response) {
      var responseJson = JSON.stringify(response)
      return JSON.parse(responseJson);
    }, function (error) {
    });
    return postQuery
  }

  //fetch posts from users this person is following, if logged in
  async function fetchFollowingPosts() {
    if (!Parse.User.current()) { return null }

    const postQuery = await Parse.Cloud.run("web_fetchTimelineFollowing", { "id": "1" }).then(function (response) {
      var responseJson = JSON.stringify(response)
      return JSON.parse(responseJson);
    }, function (error) {
      return null
    });
    return postQuery
  }

  const parameters = () => {
    if ({base64IMG}) {
      return {
        "postContent": postText, 
        "includesNFT": false,
        "photoData": base64IMG
      }

    } else if (selectedNFT != null) {
      return {
        "postContent": postText, 
        "includesNFT": true,
        "contractAddress": selectedNFT.asset_contract.address,
        "tokenId": selectedNFT.token_id
      }
    } else {
      return { 
        "postContent": postText, 
        "includesNFT": false 
      }
    }
  }

  async function handleSubmitPostAction() {
    if (postText.length == 0) { alert("You must type a comment for your post."); return }
    if (postText.length > 250) { alert("Your comment must be 250 characters or less."); return }

    const postQuery = await Parse.Cloud.run("submitTextPost", parameters()).then(function (response) {
      setPostText("")
      document.getElementById('new-posts-container-textfield').value = '';
      document.getElementById('file-picker').value = '';
      setBase64IMG(null)

      fetchPosts().then(function (response) {
        setPosts(response);
      })
    }, function (error) {
      alert(error)
    });
    return postQuery
  }

  function handleFetchOwnedNFT() {
    setSelectedNFT(null);

    if (Parse.User.current() != null) {
      fetchOwnedNFT().then(function (response) {
        setOwnedNft(response);
      })
    }
  }

  async function fetchOwnedNFT(objectId) {
    const nftQuery = await Parse.Cloud.run("fetchNFTForUserId", { "userId": Parse.User.current().id, "type": "none" }).then(function (response) {
      var responseJson = JSON.stringify(response)
      return JSON.parse(responseJson);
    }, function (error) {
    });
    return nftQuery
  }

  // Select NFT Sections ===============

  function handleSelectNFTSection() {
    if (ownedNft) {
      if (ownedNft.constructor === Array) {
        return (
            <div class="choose_nft">
              <img src="./img/icons/wallet-icon.png" width="30" height="30" />
              <input className="" type="button" value="Add NFT" onClick={onSelectNFTClick} />
          </div>)
      }
    }
  }

  function handleNFTTableSection() {
    if (ownedNft) {
      if (ownedNft.constructor === Array) {
        return ownedNft.map((singleNFT) => (
          <td> <NFTComponent
            imageUrl={singleNFT.image_url}
            title={singleNFT.name}
          />
            <input type="radio" name="nft" onClick={function () { setSelectedNFT(singleNFT) }} id="nft" /></td>
        ))
      }
    }
  }

  function handleOwnedNFTSSubmitSection() {
    return (<div>{handleSelectNFTSection()}</div>)
  }

  function handleSubmitPostSection() {
    if (Parse.User.current() != null) {
      return (<div>
        <div className="timeline"><div className="post pb-10 mt-5">
          <form className="actions">
          <input type='text' id="new-posts-container-textfield" className="new-posts-container-textfield" placeholder="  What's on your mind?" onChange={event => setPostText(event.currentTarget.value)} />
            <span onClick={function () {  handleSubmitPostAction() }} className="btn-main lead ml-5 mt-2">Submit</span> 
          </form> 
          <br />

          <table>
            <tr><td>{handleOwnedNFTSSubmitSection()}</td>
            <td><div class="choose_file">
                <i className="fa fa-photo"></i>
                <input name="Select File" id="file-picker" type="file" accept='.jpg,.png' onChange={selectFile} />
              </div>
            </td></tr>
          </table> <br />

          {base64IMG != null && //image selected
            <img id="img-holder" src="/img/" width="100" height="100" />
          }

          {showNFT ? ( //nft selected
            <div className="nft-section2">
              <h2> Select NFT </h2>
              <table><tr> {handleNFTTableSection()}</tr></table></div>
          ) : null}

        </div></div></div>)
    } else {
      return (<div className="timeline"><div className="post"> <br />
        <span onClick={function () { window.location.href = "/dapp?web_register=true" }} className="btn-main lead ml-5 mt-2 mb-5">Connect Wallet</span>
        <h3>  &nbsp; Login or connect your wallet to submit a post.</h3> <br />
      </div></div>)
    }
  }

  function handleLoadingSection() {
    if (loading === true) {
      return (<div>
        <div className="spinner-container">
            <div className="loading-spinner">
            </div>
        </div>
    </div>)
    }
  }

  function handlePostsSection() {
    if (posts) {
      return <FeedComponent posts={posts} setPosts={setPosts} setLoading={setLoading} />
    } else {
      return (<div>
        <div className="spinner-container">
            <div className="loading-spinner">
            </div>
        </div>
    </div>)
    }
  }

  // image file selection 
  const [base64IMG, setBase64IMG] = useState()
  const selectFile = (event) => {
    let reader = new FileReader();
    reader.onload = function () {
      setBase64IMG(reader.result)

      let output = document.getElementById('img-holder');
      output.src = reader.result;
    }
    if (event.target.files[0]) {
      reader.readAsDataURL(event.target.files[0]);
    }
  }

    return (
        <div>
            <GlobalStyles />
        {/* <div className="alert alert-light ml-5 mr-5 mt-20" role="alert">
        🎉 Welcome to the Veep.AR beta. Connect your ETH wallet and submit a post to enter our ETH giveaway! (Ending 10.08.22) 🎉 <br /> <br /><u><a href="/info">Learn more about Veep.AR</a></u>
        </div> */}
            
            <section className='jumbomain breadcumb no-bg mt-5 mb-0'>
          <div className="header-section pt-2 pl-2">
            <i className="fa fa-user"><a href="/topusers">Top Users</a></i> <br />
            <i className="fa fa-star"><a href="/top">Top Communities</a></i>
          </div>

          <RecentUsersComponent showTitle={true}/>

                    {handleSubmitPostSection()}
                    <div className="timeline">
                        {handlePostsSection()}
                        {handleLoadingSection()}
                </div>
            </section>
            <Footer />
        </div>
    );
}