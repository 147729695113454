import Reveal from 'react-awesome-reveal';
import { keyframes } from "@emotion/react";

const fadeInUp = keyframes`
  0% {
    opacity: 0;
    -webkit-transform: translateY(40px);
    transform: translateY(40px);
  }
  100% {
    opacity: 1;
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
`;
const fadeIn = keyframes`
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
`;

const FeaturesBox = () => (
    <Reveal className='onStep' keyframes={fadeInUp} delay={300} duration={900} triggerOnce> 
     
     
     <section class="py-0">
      <div class="container max-w-6xl mx-auto">
        <div class="grid grid-cols-4 gap-8 mt-10 sm:grid-cols-8 lg:grid-cols-12 sm:px-8 xl:px-0">

          <div class="relative flex flex-col items-center justify-between col-span-4 px-8 py-12 space-y-4 overflow-hidden bg-clear sm:rounded-xl">
            <div class="p-3 text-white bg-purple-600 rounded-full">
            <i className="bg-color-2 i-boxed icon_camera"></i>
             </div>
            <h4 class="text-xl font-medium text-purple-600">Augmented Reality</h4>
            <p class="text-base text-center text-white">Use our augmented reality camera to see virtual content, take photos, record videos, and join virtual spaces.</p>
          </div>

  
          <div class="relative flex flex-col items-center justify-between col-span-4 px-8 py-12 space-y-4 overflow-hidden bg-clear sm:rounded-xl">
            <div class="p-3 text-white bg-purple-600 rounded-full">
            <i className="bg-color-2 i-boxed icon_house"></i>
              </div>
            <h4 class="text-xl font-medium text-purple-600">Virtual Spaces</h4>
            <p class="text-base text-center text-white">Create your own persistant virtual space and decorate it with objects, or visit other spaces to add objects.</p>
          </div>
          

          <div class="relative flex flex-col items-center justify-between col-span-4 px-8 py-12 space-y-4 overflow-hidden bg-clear sm:rounded-xl">
            <div class="p-3 text-white bg-purple-600 rounded-full">
            <i className="bg-color-2 i-boxed icon_mic"></i>
            </div>
            <h4 class="text-xl font-medium text-purple-600">Live Chat</h4>
            <p class="text-base text-center text-white">Our virtual spaces are Live!  Featuring live voice chat and message rooms. </p>
          </div>

        </div>
      </div>
      <script src="https://cdnjs.cloudflare.com/ajax/libs/alpinejs/2.8.0/alpine.js"></script>
    </section> 
    </Reveal>
);
    
export default FeaturesBox; 

