import SimpleImageSlider from "react-simple-image-slider";
import Reveal from 'react-awesome-reveal';
import { keyframes } from "@emotion/react";

const fadeInUp = keyframes`
  0% {
    opacity: 0;
    -webkit-transform: translateY(40px);
    transform: translateY(40px);
  }
  100% {
    opacity: 1;
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
`;
const fadeIn = keyframes`
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
`;

const images = [
  { url: "./img/App1.png" },
  { url: "./img/App2.png" },
  { url: "./img/App3.png" },
  { url: "./img/App4.png" },
];

const AppImgBox = () => {
  return (
    <Reveal className='onStep' keyframes={fadeInUp} delay={600} duration={900} triggerOnce>
      <section>
        <div class="container2">
          <div className='col-lg-12 text-center'>
            <h3 class="mt-1 text-2xl font-bold text-left text-white-800 sm:mx-6 sm:text-3xl md:text-4xl lg:text-5xl sm:text-center sm:mx-0 pb-8">
              <span class="soraFont">
              Mobile App</span>
            </h3>
            <center>
             
              <div>
                    <SimpleImageSlider
                      width={255}
                      height={471}
                      images={images}
                      showBullets={true}
                      showNavs={true}
                      bgColor={"#0000ffff;"}
                    />
                  </div>
            </center>
          </div>
        </div>
      </section>
    </Reveal>
  );
}

export default AppImgBox;