import React, { useEffect, useState } from "react";
import Footer from '../components/footer';
import { Link } from '@reach/router';
import { Accordion } from 'react-bootstrap';
import FeaturesBox from '../components/FAQBox';
import { createGlobalStyle } from 'styled-components';
import FAQSection from '../components/FAQBox';

const GlobalStyles = createGlobalStyle`
  .navbar {
    border-bottom: solid 1px rgba(255, 255, 255, .1) !important;
  }
`;

//this page has multiple uses when creating an account
//register with wallet (web)
//link account
//log in with wallet (modal iOS)
//create account with wallet (modal iOS)

const search = window.location.search;
const params = new URLSearchParams(search);
const shareId = params.get('sessionId');
const isNew = params.get('new'); //new account, or linking to existing
const error = params.get('error');
const web_register = params.get('web_register'); //used when registering on web, show different success message

function copy(text){
    if(navigator.clipboard) {
        navigator.clipboard.writeText(text)
    }
}

function mainUI() {
    if (shareId === "LinkSuccess") {
        return (
            <div>
                Successfully linked user.  Please close this page and manually return back to the Veep app. < br /> <br />
            </div>
        )
    } else if (!shareId.includes("Error")) { 
        if (web_register === "true" && isNew === "true") {
            return (
                <div>
                <span onClick={function () { window.location.href = "/home" } } className="btn-main lead">Return Home</span>  <br /> <br />
                   Account created.  <br /> <br />
                   <br /><br />
                </div>
            )
        } 
        if (web_register === "true" && isNew === "false") {
            return (
                <div>
                <span onClick={function () { window.location.href = "/home" } } className="btn-main lead">Return Home</span>  <br /> <br />
                   Login with wallet success.  <br /> <br />
                   <br /><br />
                </div>
            )
        } 
        if (isNew === true) {
            return (
                <div>
                <span onClick={function () { copy(shareId) } } className="btn-main lead">Copy Code</span>  <br /> <br />
                   Account created.  Please copy your login code and manually return back to the the Veep app. <br /> <br />
                   <br /><br />
                </div>
            )
        } else {
            return (
                <div>
                <span onClick={function () { copy(shareId) } } className="btn-main lead">Copy Code</span>  <br /> <br />
                Log in success.  Please copy your login code and manually return back to the Veep app. <br /> <br />
                </div>
            )
        }
    }  else {
        return (
            <div>
            We encountered an error.  Please try again, or contact support. <br /> <br />
            </div>
        )
    }
}

const LoginRedirect = () => {
    return ( 
<div>
<GlobalStyles/>
  <section className='jumbotron breadcumb no-bg'>
    <div className='mainbreadcumb'>
      <div className='container'>
        <div className='row'>
        {mainUI()}
        </div>
        
      </div>
    </div>
  </section>

  <Footer />
</div>
    );
}

export default LoginRedirect;