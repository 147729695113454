import React, { useEffect, useState } from "react";
import Footer from '../components/footer';
import { createGlobalStyle } from 'styled-components';
import Parse from 'parse';
import Avatar from "@material-ui/core/Avatar";
import UserComponent from "../components/usercomponent";
import NavbarCollapse from "react-bootstrap/esm/NavbarCollapse";

// Recent User List component
// displays list of recent users, will fetch recent users from back-end
//  - parameter: communitySlug (optional) - if provided, will fetch recent users for communitySlug instead
//
function RecentUsersComponent({communitySlug, showTitle}) {

    const [users, setUsers] = useState([{},]);
    useEffect(() => {
        onScreenLoad();
    }, [])

    const onScreenLoad = () => {
        fetchUsers(communitySlug).then(function (response) {
            setUsers(response);
        })
    }

    async function fetchUsers(communitySlug) {
        var fetchName = "fetchRecentUserList";
        var params = {};

        if (communitySlug != null) {
            fetchName = "fetchCommunityFollowers"
            params = { "slug": String(communitySlug), 
            "web_checkUsers": true }
        }

        const postQuery = await Parse.Cloud.run(fetchName, params).then(function (response) {
            var responseJson = JSON.stringify(response)
           // alert(fetchName + " -- " + responseJson)
            return JSON.parse(responseJson);
        }, function (error) {
        });
        return postQuery
    }

    function getUsernameDisplay(user) {
        if (user.ens != null) {
            return String(user.ens)
        } else {
            if (user.verifiedWallet === true) {
                return Address(String(user.username))
            }

            return user.username
        }
    }

    function Address( userAddress ) {
        if (userAddress && userAddress.length > 0) {
          return (
            <span className="address">{userAddress.substring(0, 5)}…{userAddress.substring(userAddress.length - 4)}</span>
          );
        } else {
          return ( 'address-error')
        }
      }

    function handleUserListSection() {
        if (users && users.length >= 1) {
            if (users.constructor === Array) {
              if (!users[0].username) { return  }

                return (
                    <div>{users.map((user) => (
                        <td className="userlist-single-container"><div className="userlist-single">
                            <div className="userlist-avatar"> <UserComponent user={user} avatarURL={user.avatarURL} /></div>
                            <div class="userlist-caption"> <a href={"/user?userId=" + user.username}>@{getUsernameDisplay(user)}</a></div>
                        </div></td> 
                    ))}</div>
                )
            }
        }
    }

    function mainUI() {
        if (!users) { return }

        return (<div>
            <div className="userlist-container">
                <div className="userlist">

                   {showTitle &&
                   <h2> Recent Users</h2>} 

                    <table> <tr> {handleUserListSection()}</tr></table></div></div></div>)
    }

    return (
       <div>{mainUI()}</div>
    );
}

export default RecentUsersComponent;