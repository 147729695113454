import Reveal from 'react-awesome-reveal';
import { keyframes } from "@emotion/react";

const fadeInUp = keyframes`
  0% {
    opacity: 0;
    -webkit-transform: translateY(40px);
    transform: translateY(40px);
  }
  100% {
    opacity: 1;
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
`;
const fadeIn = keyframes`
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
`;

const TeamBox = () => (
    <Reveal className='onStep' keyframes={fadeInUp} delay={300} duration={900} triggerOnce>
      <section class="w-full py-12 bg-white lg:py-24">
            <div class="max-w-6xl px-12 mx-auto text-center">
                <div class="soraFont">
                <div class="space-y-12 md:text-center">
                    <div class="max-w-3xl mb-20 space-y-5 sm:mx-auto sm:space-y-4">
                        <h2 class="relative text-4xl font-extrabold tracking-tight sm:text-5xl">Our Team</h2>
                    </div>
                </div>

                <div class="grid grid-cols-1 gap-10 sm:grid-cols-2 lg:grid-cols-3">
                    <div class="w-full border border-gray-200 rounded-lg shadow-sm">
                        <div class="flex flex-col items-center justify-center p-10">
                            <img class="w-32 h-32 mb-6 rounded-full" src="./img/purple.png" />
                            <h2 class="text-lg font-medium">Hunter</h2>
                            <p class="font-medium text-purple-500">Founder / Dev</p>
                            <p class="text-gray-400">2015
                            </p></div>
                        </div>

                    <div class="w-full border border-gray-200 rounded-lg shadow-sm">

                        <div class="flex flex-col items-center justify-center p-10">
                            <img class="w-32 h-32 mb-6 rounded-full" src="./img/purple.png" />
                            <h2 class="text-lg font-medium">Devan</h2>
                            <p class="font-medium text-purple-500">PR / Marketing</p>
                            <p class="text-gray-400">2021
                            </p></div>
                        </div>
                    <div class="w-full border border-gray-200 rounded-lg shadow-sm">
                        <div class="flex flex-col items-center justify-center p-10">
                            <img class="w-32 h-32 mb-6 rounded-full" src="./img/purple.png" />
                            <h2 class="text-lg font-medium">Wann</h2>
                            <p class="font-medium text-purple-500">UI/UX Designer</p>
                            <p class="text-gray-400">2021
                            </p></div>
                    </div>
                    <div class="w-full border border-gray-200 rounded-lg shadow-sm">
                        <div class="flex flex-col items-center justify-center p-10">
                            <img class="w-32 h-32 mb-6 rounded-full" src="./img/purple.png" />
                            <h2 class="text-lg font-medium">Josh LJY</h2>
                            <p class="font-medium text-purple-500">Content / Writer</p>
                            <p class="text-gray-400">2021
                            </p></div>
                    </div>
                    <div class="w-full border border-gray-200 rounded-lg shadow-sm">
                        <div class="flex flex-col items-center justify-center p-10">
                            <img class="w-32 h-32 mb-6 rounded-full" src="./img/purple.png" />
                            <h2 class="text-lg font-medium">Now Hiring</h2>
                            <p class="font-medium text-purple-500">Android Dev</p>
                            <p class="text-gray-400">2021
                            </p></div>
                    </div>
                    <div class="w-full border border-gray-200 rounded-lg shadow-sm">
                        <div class="flex flex-col items-center justify-center p-10">
                            <img class="w-32 h-32 mb-6 rounded-full" src="./img/purple.png" />
                            <h2 class="text-lg font-medium">Now Hiring</h2>
                            <p class="font-medium text-purple-500">Business Analyst</p>
                            <p class="text-gray-400">2021
                            </p></div>
                    </div>
                </div>
    </div>
            </div>
        </section>
    </Reveal>
);

export default TeamBox;
