import React, { useEffect, useState } from "react";
import Footer from '../components/footer';
import { createGlobalStyle } from 'styled-components';
import Parse from 'parse';
import PostComponent from "../components/postcomponent";
import NFTComponent from "../components/nftcomponent";
import RecentUsersComponent from "../components/recentuserscomponent";

const GlobalStyles = createGlobalStyle`
  .navbar {
    border-bottom: solid 1px rgba(255, 255, 255, .1) !important;
  }

  .community_section {
    font-size: 1.3vw;
  }
`;

export default function Community() {
  const search = window.location.search;
  const params = new URLSearchParams(search);
  const communityId = params.get('id');

  const [community, setCommunity] = useState([{},]); //fetched from veep backend
  const [communityNfts, setCommunityNfts] = useState([{}, ""]); //opensea api 

  const [commentText, setCommentText] = useState([{}, ""]);
  const [posts, setPosts] = useState([{},]);

  const [ownedNft, setOwnedNft] = useState([{}, ""]);
  const [selectedNFT, setSelectedNFT] = useState([{}, ""]);

  const [following, setFollowing] = useState([{},]);

  const [showNFT, setShowNFT] = React.useState(false)
  const onClick = () => setShowNFT(!showNFT)

  const onScreenLoad = () => {
    setSelectedNFT(null);
    setCommunityNfts(null);
    setPosts(null);
    setCommunity(null);

    fetchCommunity().then(function (response) {
      setCommunity(response);

      updateIsFollowing() // set default join community button "Leave" / "Join" status

      fetchCommunityNfts(response.contractAddress).then(function (response) {
          if (!response) { return }
          const result = response["assets"] ?? null;
          setCommunityNfts(result);
      })
    })

    handleFetchOwnedNFT() //only need to fetch owned NFT if logged in

    fetchPosts().then(function (response) {
      setPosts(response);
    })
  }

  useEffect(() => {
    onScreenLoad();
  }, [])

  // Fetches ======================

  async function fetchCommunity() {
    const postQuery = await Parse.Cloud.run("fetchCommunityForSlug", { "slug": communityId }).then(function (response) {
      var responseJson = JSON.stringify(response)
      return JSON.parse(responseJson);
    }, function (error) {
    });
    return postQuery
  }

  async function fetchPosts() {
    const postQuery = await Parse.Cloud.run("web_fetchCommunityPostForSlug", { "slug": communityId }).then(function (response) {
      var responseJson = JSON.stringify(response)
      return JSON.parse(responseJson);
    }, function (error) {
    });
    return postQuery
  }

  async function fetchCommunityNfts(contractAddress) {
    if (!contractAddress || contractAddress === null) { return }
    var request = require('request-promise');

    var _contractAddress = contractAddress;

    const options = {
        url: 'https://api.opensea.io/api/v1/assets?asset_contract_address=' + String(_contractAddress),
        method: 'GET',
        headers: {
          'X-API-Key': '867399b4417343b89dd71871c8b624fd'
        }
    };

    const nameRequest = await request(options).then(function (nameResponse) {
        if (nameResponse != null) {
            const jsonObject = JSON.parse(nameResponse)
            //alert(nameResponse)
            return jsonObject
        } else {
            return "error"
        }
    }, function(error) {
        return "error"
    });

    return nameRequest
}

// Comments / Posts =========================

  function handleCommentsSection() {
    if (!posts) { return }
    if (posts.constructor === Array) {
      return posts.map((post) => (
        <PostComponent
        post={post}
        postId={post.objectId}
        username={post.username}
        PostContent={post.PostContent}
        image={post.postImageUrl}
        avatarURL={post.avatarURL}
        videoUrl={post.videoUrl}
        isAuthorVerified={post.isVerified}
        isAuthorENSVerified={post.isAuthorENSVerified}
        authorENS={post.ensDisplayName}
        isPostSigned={post.verifiedPost}
        communitySlug={post.CommunitySlug}
        nftImageUrl={post.nftImageUrl}
        collectionName={post.collectionName}
        token_id={post.tokenId}
        createdAt={post.createdAt}
        likeCount={post.Likes}
        postCreator={post.userPointer}
      />
      ))
    } else {
      return (<div className="timeline"><div className="post"> <br />
       <span className="inline"><span aria-hidden="true" className="icon-chat ml-5"></span> <h3>  &nbsp; &nbsp; No comments yet.</h3></span> <br /></div></div>)
    }
  }

  function handleSubmitCommentSection() {
    if (Parse.User.current() != null) {
      return (
        <div className="post pb-5 mt-10">
          <form className="actions">
            <input type='text' id="new-posts-container-textfield" className="new-posts-container-textfield" placeholder="  Veep your reply" onChange={event => setCommentText(event.currentTarget.value)} />
            <span onClick={function () { handleSubmitPostAction() }} className="btn-main lead ml-5 mt-2">Submit</span>  {handleOwnedNFTSSubmitSection()}
          </form>
        </div>)
    } else {
      return (<div className="timeline"><div className="post"> <br />
      <span onClick={function () { window.location.href="/dapp" }} className="btn-main lead ml-3 mt-2 mb-3">Connect Wallet</span> <h3>  &nbsp; &nbsp; Login or connect your wallet to submit a comment.</h3> <br /></div></div>)
    }
  }

  const parameters = () => {
    if (selectedNFT != null) {
      return {
        "postContent": commentText,
        "includesNFT": true,
        "contractAddress": selectedNFT.asset_contract.address,
        "tokenId": selectedNFT.token_id,
        "communitySlug": communityId
      }
    } else {
      return {
        "postContent": commentText,
        "includesNFT": false,
        "communitySlug": communityId
      }
    }
  }

  async function handleSubmitPostAction() {
    if (commentText.length == 0) { alert("You must type a comment for your post."); return }
    if (commentText.length > 250) { alert("Your comment must be 250 characters or less."); return }

    const postQuery = await Parse.Cloud.run("submitTextPost", parameters()).then(function (response) {

      setCommentText("")
      document.getElementById('new-posts-container-textfield').value = '';

      fetchPosts().then(function (response) {
        setPosts(response);
      })
    }, function (error) {
      alert("We encountered an error while submitting your post, please try again.")
    });
    return postQuery
  }

  // Select NFT Sections ===============

  async function fetchOwnedNFT() {
    const nftQuery = await Parse.Cloud.run("fetchNFTForUserId", { "userId": Parse.User.current().id, "type": "none" }).then(function (response) {
      var responseJson = JSON.stringify(response)
      return JSON.parse(responseJson);
    }, function (error) {
    });
    return nftQuery
  }

  function handleFetchOwnedNFT() {
    if (Parse.User.current() != null) {
      fetchOwnedNFT().then(function (response) {
        setOwnedNft(response);
      })
    }
  }

  function handleSelectNFTSection() {
    if (ownedNft) {
      if (ownedNft.constructor === Array) {
        return (<div> <br /> <br />
          <input className="x ml-5 pd-10" type="button" value="Add NFT" onClick={onClick} />

          {showNFT ? (
            <div className="nft-section2">
              <h2> Select NFT </h2>
              <table> <tr>
                {handleNFTTableSection()}
              </tr></table></div>
          )
            : null}
        </div>)
      }
    }
  }

  function handleNFTTableSection() {
    if (ownedNft) {
      if (ownedNft.constructor === Array) {
        return ownedNft.map((singleNFT) => (
          <td> <NFTComponent
            imageUrl={singleNFT.image_url}
            title="ok"
          />
            <input type="radio" name="nft" onClick={function () {
              setSelectedNFT(singleNFT)
            }} id="nft" /></td>
        ))
      }
    }
  }

  function handleOwnedNFTSSubmitSection() {
    return (<div>{handleSelectNFTSection()}</div>)
  }

  // join community =================

  async function fetchIsFollowing(id) {
    const userQuery = await Parse.Cloud.run("fetchCommunityFollowers", { "slug": communityId, "web_checkFollow": true }).then(function (response) {
      return String(response);
    }, function (error) {
      return "false";
    });
    return userQuery
  }

  async function toggleFollowQuery() {
    const postQuery = await Parse.Cloud.run("joinCommunity", { "slug": communityId }).then(function (response) {
      var responseJson = JSON.stringify(response)
      return JSON.parse(responseJson);
    }, function (error) {
    });
    return postQuery
  }

  function handleJoinButtonSection() {
    if (Parse.User.current()) {
      return ( <div> <span onClick={function () { doFollowToggle() }} className="btn-main lead mt-2">{followText()}</span> </div>)
    } 
  }

  function followText() {
    if (following === true) {
      return "Leave"
    } else {
      return "Join"
    }
  }

  async function doFollowToggle() {
    toggleFollowQuery().then(function (response) {
      if (response != null) {
        if (String(response) === "Left") {
          setFollowing(false)
        } else {
          setFollowing(true)
        }
      }
    })
  }

  function updateIsFollowing() {
    fetchIsFollowing(communityId).then(function (response) {
      if (response === 'true') {
        setFollowing(true)
      }
      if (response === 'false') {
        setFollowing(false)
      }
    })
  }

  // community data section - fetched from opensea api

  function displayCommunityNFTSection() {
    if (!communityNfts || communityNfts === null) { return } 
    return (<div className="nft-section">
        <table> <tr>
          {handleCommunityNftsSection()}
        </tr></table></div>)
  }

  function handleCommunityNftsSection() {
    if (!communityNfts || communityNfts === null) { return }

    if (communityNfts && communityNfts.length > 2) {
      if (communityNfts.constructor === Array) {
        return communityNfts.map((asset) => (

          <td> <a href={"/asset?nftId=" + asset["asset_contract"].address + "-" + asset.token_id}>
            <NFTComponent
              imageUrl={asset.image_url}
              title={asset["asset_contract"].name + " #" + asset.token_id} />
          </a> </td>
        ))
      }
    }
  }


  function handleMintDateSection() {
    if (community.mintDate != null && community.mintDate != 'undefined') {
      return (<div> <div className="user-follow-info mt-5 pt-3 pb-3"> Mint date:  {getMintDate()} </div> </div>);
    }
  }

  function getMintDate() {
    if (community.mintDate != null && community.mintDate != 'undefined') {
      return formattedDate(Date(community.mintDate))
    } else {
      return ""
    }
  }

  // Main UI ============

  function mainCommunityUI() {
    if (community) {
      return (<div>
        <div className="timeline">
          <center>
            <div className="user-name-text">{community.name}</div>
            <img src={community.imageURL} className="single-nft" /> <br />
            <table className="user-follow-info mt-5"> <tr><td>{community.memberCount}</td></tr>
              <tr><td>Members</td></tr></table>
            <br />
            <RecentUsersComponent communitySlug={community.slug} />
            {handleMintDateSection()}
            {handleJoinButtonSection()}
            {displayCommunityNFTSection()}
          </center>
        </div>
      </div>)
    } else {
      return (<div>
        <div className="spinner-container">
            <div className="loading-spinner">
            </div>
        </div>
    </div>)
    }
  }

  function formattedDate(date) {
    var options = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' };
    var today = new Date();

    return today.toLocaleDateString("en-US", options);
  }

  function time_ago(time) {
    switch (typeof time) {
      case 'number':
        break;
      case 'string':
        time = +new Date(time);
        break;
      case 'object':
        if (time.constructor === Date) time = time.getTime();
        break;
      default:
        time = +new Date();
    }
    var time_formats = [
      [60, 'seconds', 1], // 60
      [120, '1 minute ago', '1 minute from now'], // 60*2
      [3600, 'minutes', 60], // 60*60, 60
      [7200, '1 hour ago', '1 hour from now'], // 60*60*2
      [86400, 'hours', 3600], // 60*60*24, 60*60
      [172800, 'Yesterday', 'Tomorrow'], // 60*60*24*2
      [604800, 'days', 86400], // 60*60*24*7, 60*60*24
      [1209600, 'Last week', 'Next week'], // 60*60*24*7*4*2
      [2419200, 'weeks', 604800], // 60*60*24*7*4, 60*60*24*7
      [4838400, 'Last month', 'Next month'], // 60*60*24*7*4*2
      [29030400, 'months', 2419200], // 60*60*24*7*4*12, 60*60*24*7*4
      [58060800, 'Last year', 'Next year'], // 60*60*24*7*4*12*2
      [2903040000, 'years', 29030400], // 60*60*24*7*4*12*100, 60*60*24*7*4*12
      [5806080000, 'Last century', 'Next century'], // 60*60*24*7*4*12*100*2
      [58060800000, 'centuries', 2903040000] // 60*60*24*7*4*12*100*20, 60*60*24*7*4*12*100
    ];
    var seconds = (+new Date() - time) / 1000,
      token = 'ago',
      list_choice = 1;

    if (seconds == 0) {
      return 'Just now'
    }
    if (seconds < 0) {
      seconds = Math.abs(seconds);
      token = 'from now';
      list_choice = 2;
    }
    var i = 0,
      format;
    while (format = time_formats[i++])
      if (seconds < format[0]) {
        if (typeof format[2] == 'string')
          return format[list_choice];
        else
          return Math.floor(seconds / format[2]) + ' ' + format[1] + ' ' + token;
      }
    return time;
  }
  
  return (
    <div>
      <GlobalStyles />
      <section className='jumbotro2 breadcumb no-bg mt-20'>
       {mainCommunityUI()}
      </section>

      <section className='jumbotro2 breadcumb no-bg'>
        <div className="comments-section">
          {handleSubmitCommentSection()} <br />
          {handleCommentsSection()}
        </div>
      </section>

      <Footer />
    </div>
  );
}