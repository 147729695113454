import Parse from 'parse';
import React, { useEffect, useState } from "react";
import { createGlobalStyle } from 'styled-components';
import Footer from '../components/footer';
import NFTComponent from "../components/nftcomponent";
import PostComponent from "../components/postcomponent";
import UserComponent from "../components/usercomponent";


const GlobalStyles = createGlobalStyle`
  .navbar {
    border-bottom: solid 1px rgba(255, 255, 255, .1) !important;
  }

`;

export default function User() {
    const search = window.location.search;
    const params = new URLSearchParams(search);
    const userId = params.get('userId');
    const [user, setUser] = useState([{},]);
    const [following, setFollowing] = useState([{},]);

    const [posts, setPosts] = useState([{},]);
    const [ownedNft, setOwnedNft] = useState([{},]);

    const onScreenLoad = () => {
        setOwnedNft("null")
        setUser(null);
        setPosts(null);

        fetchUser().then(function (userResponse) {
            setUser(userResponse);
            updateIsFollowing(userResponse.objectId); //set default isFollowing this user status 

            fetchPosts(userResponse.objectId).then(function (response) {
                setPosts(response);

                fetchNFT(userResponse.objectId).then(function (nftResponse) {
                    if (nftResponse.length <= 3) { setOwnedNft("null"); return  }
                    setOwnedNft(nftResponse);
                })
            })
        })
    }

    useEffect(() => {
        onScreenLoad();
    }, [])

    // FETCHES ================

    async function fetchUser() {
        const userQuery = await Parse.Cloud.run("web_fetchUserInfoCloud", { "username": userId, "userId": "none" }).then(function (response) {
            var responseJson = JSON.stringify(response)
            return JSON.parse(responseJson);
        }, function (error) {
        });
        return userQuery
    }

    async function fetchPosts(objectId) {
        const postQuery = await Parse.Cloud.run("web_fetchUserPosts", { "userId": objectId }).then(function (response) {
            var responseJson = JSON.stringify(response)
            return JSON.parse(responseJson);
        }, function (error) {
        });
        return postQuery
    }

    async function fetchNFT(objectId) {
        //if (user.verifiedWallet != true) { return }
        const nftQuery = await Parse.Cloud.run("fetchNFTForUserId", { "userId": objectId, "type": "none" }).then(function (response) {
            var responseJson = JSON.stringify(response)
            return JSON.parse(responseJson);
        }, function (error) {
            return "null"
        });
        return nftQuery
    }

    async function toggleFollowQuery() {
        const postQuery = await Parse.Cloud.run("toggleFollow", { "toUserId": user.objectId }).then(function (response) {
            var responseJson = JSON.stringify(response)
            return JSON.parse(responseJson);
        }, function (error) {
        });
        return postQuery
    }

    // UI ======================

    function handleTimelineSection() {
        if (!posts) { return }
        
        if (posts.constructor === Array) {
            return posts.map((post) => (
                <PostComponent
                    post={post}
                    postId={post.objectId}
                    username={post.username}
                    PostContent={post.PostContent}
                    image={post.postImageUrl}
                    avatarURL={post.avatarURL}
                    videoUrl={post.videoUrl}
                    isAuthorVerified={post.isVerified}
                    isAuthorENSVerified={post.isAuthorENSVerified}
                    authorENS={post.ensDisplayName}
                    isPostSigned={post.verifiedPost}
                    communitySlug={post.CommunitySlug}
                    nftImageUrl={post.nftImageUrl}
                    collectionName={post.collectionName}
                    token_id={post.tokenId}
                    createdAt={post.createdAt}
                    likeCount={post.Likes}
                    postCreator={post.userPointer}
                />
            ))
        }
    }

    function getUsernameDisplay() {
        if (user.ens != null) {
            return String(user.ens)
        } else {
            return user.username
        }
    }

    function handleVerifiedBadge() {
        if (user.isVerified === true) {
          return ( <div><img src="./img/icons/VerifiedUser.png" height="16" width="16" /> </div>)
        }
      } 
    
      function handleVerifiedENSBadge() {
        if (user.ens != null) {
          return ( <div><img src="./img/icons/VerifiedENS.png" height="16" width="16" /> </div>)
        }
      } 

    function handleWalletSection() {
        if (user.publicAddress != null) {
            return ( <div><center> ETH Address: <br />{user.publicAddress } </center></div> ) 
        }
    }

    function handleOwnedNFTSection() {
        if (ownedNft && ownedNft.length > 1) { 
            if (ownedNft.constructor === Array) {
                if (!ownedNft[0].token_id) { return }
                return ownedNft.map((asset) => ( 

                    <td> <a href={"/asset?nftId=" + asset["asset_contract"].address + "-" + asset.token_id}>
                        <NFTComponent
                        imageUrl={asset.image_url} 
                        title={asset["asset_contract"].name + "#" + asset.token_id} />
                    </a> </td>
                ))
            }
        }
    }

    function handleEditProfileButton() {
        if (Parse.User.current()) {
            if (user.username === Parse.User.current().get('username')) {
                return ( <div>  <span onClick={function () { window.location.href="/edit" }} className="btn-main lead ml-5 mt-2">Edit Profile</span>  </div>)
            }
        }
    }

    function handleFollowButtonSection() {
        if (Parse.User.current()) {
            if (user.username === Parse.User.current().get('username')) {
                handleEditProfileButton()
            } else {
                return ( <div> <span onClick={function () { doFollowToggle() }} className="btn-main lead mt-2">{followText()}</span> </div>)
            }
        } 
    }

    function getUserFollowersCount() {
        if (user.followerCount != null) {
            return user.followerCount
        } else {
            return 0
        }
    }

    function getUserFollowingCount() {
        if (user.followingCount != null) {
            return user.followingCount
        } else {
            return 0
        }
    }

    function followText() {
        if (following === true) {
            return "Unfollow"
        } else {
            return "Follow"
        }
    }

    async function doFollowToggle() {
        toggleFollowQuery().then(function (response) {
            if (response != null) {
                if (String(response) === "Deleted") {
                    setFollowing(false)
                } else {
                    setFollowing(true)
                }
            }
        })
    }

    function updateIsFollowing(id) {
        fetchIsFollowing(id).then(function (response) {
            if (response === '[object Object]') {
                setFollowing(true)
            } 
            if (response === 'false') {
                setFollowing(false)
            }
        })
    }

    async function fetchIsFollowing(id) {
        const userQuery = await Parse.Cloud.run("fetchFollowersForUser", { "toUserId": id, "web_checkFollow": true }).then(function (response) {
            return String(response);
        }, function (error) {
            return "false";
        });
        return userQuery
    }

    function displayOwnedNFTSection() {


       // if (!ownedNft) { return null }
        if (ownedNft.constructor != Array) { return }
        return (<div className="nft-section">
            <table><tr>
                {handleOwnedNFTSection()}
            </tr></table></div>)
    }

    function mainUI() {
        if (user) {
            return (<div>
                <center> <div className="user-avatar-header">
                <UserComponent avatarURL={user.imageUrl} user={user}/> </div> </center>
                <center><div className="user-name-text">@{getUsernameDisplay()}</div>  {handleVerifiedBadge()} {handleVerifiedENSBadge()} <br />
                    <p>{user.aboutMe}</p>
                    {handleFollowButtonSection()}
                    {handleEditProfileButton()}
                    <table className="user-follow-info"> <tr><td>{getUserFollowersCount()}</td> <td>{getUserFollowingCount()}</td></tr>
                        <tr><td>Followers</td><td>Following</td></tr></table>
                </center> 

                {displayOwnedNFTSection()}

                <div className="timeline">
                    {handleTimelineSection()}
                </div>
            </div>)

        } else {
            return (<div>
                <div className="spinner-container">
                    <div className="loading-spinner">
                    </div>
                </div>
            </div>)
        }
    }

    return (
        <div>
            <GlobalStyles /> 
            <div className='jumbotron mt-25 pt-10'>
               {mainUI()}
            </div>
            <Footer />
        </div>
    );
}