import React, { useEffect, useState } from "react";
import Footer from '../components/footer';
import { createGlobalStyle } from 'styled-components';
import Parse from 'parse';

const GlobalStyles = createGlobalStyle`
  header#myHeader.navbar.white {
    
  }
  .box-login p{
    color: white !important;
  }
  .box-login {
    border-radius: 3px;
    padding: 40px 50px;
  }
`;

async function loginUser({username, password}) {
  const user = new Parse.User();
    
  user.set('username', username);
  user.set('password', password);

  user.logIn().then((user) => {
    window.location.href = `/home`;
  }).catch(err => {      
    alert(err.message);
  });
}



export default function Login() { 

  const [username, setUserName] = useState();
  const [password, setPassword] = useState();

  const handleSubmit = async e => {
    await loginUser({
      username,
      password}
    );
  }
  
  return (
    <div>
      {mainUI()}
    </div>
  )

  function handleIsLoggedIn() {
    if (Parse.User.current() != null) {
      return ( <div className="timeline ml-10"> You are already logged in as: @{Parse.User.current().get("username")}</div>)
    } else {
      return (
        <div className="box-login">
          <h3 className="mb10">Sign In</h3>
          <div className="spacer-half"></div>
          <form name="contactForm" id='contact_form' className="form-border" action="#" onSubmit={handleSubmit}>

            <div className="field-set">
              <input type='text' className="form-control" placeholder="username" onChange={e => setUserName(e.target.value)} />
            </div>

            <div className="field-set">
              <input type='password' className="form-control" placeholder="password" onChange={e => setPassword(e.target.value)} />
            </div>

            <div className="field-set">
              <input type='submit' value='Login' className="btn btn-main btn-fullwidth color-2" />
            </div>

            <div className="spacer-half"></div>
            <center> <h1>or</h1></center>
            <div className="spacer-half"></div>
            <span onClick={function () { window.location.href = "/dapp?web_register=true" }} className="btn-main lead">Sign-In With Ethereum</span> <br />
            <span onClick={function () { window.location.href = "/dapp?web_register=true" }} className="btn-main lead">Register With Ethereum</span> <br /> <br />
          </form>
        </div>
      )
    }
  }

  function mainUI() {
    return ( 
      <div>
        <GlobalStyles />

        <section className='jumbotron breadcumb no-bg' style={{ backgroundImage: `url(${'./img/background/6.jpg'})` }}>
          <div className='mainbreadcumb'>
            <div className='container'>
              <div className='row align-items-center px-0'>
                <div className="col-lg-4 offset-lg-4 m-auto px-0">
                  {handleIsLoggedIn()}
                </div>
              </div>
            </div>
          </div>
        </section>

        <Footer />
      </div>
    )
  }
}
