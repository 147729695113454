import React, { useEffect, useState } from "react";
import Footer from '../components/footer';
import { createGlobalStyle } from 'styled-components';
import Parse from 'parse';
import Web3 from 'web3';
import { useWeb3React } from '@web3-react/core'
import { InjectedConnector } from "@web3-react/injected-connector";
import { WalletLinkConnector } from "@web3-react/walletlink-connector";
import { WalletConnectConnector } from "@web3-react/walletconnect-connector";

const Injected = new InjectedConnector({
  supportedChainIds: [1, 3, 4, 5, 42]
 });

 const WalletConnect = new WalletConnectConnector({
  rpcUrl: `https://mainnet.infura.io/v3/e16706f3b2d94592a10601fc2d6ccbf4`,
  bridge: "https://bridge.walletconnect.org",
  qrcode: true,
 });

 const CoinbaseWallet = new WalletLinkConnector({
  url: `https://mainnet.infura.io/v3/e16706f3b2d94592a10601fc2d6ccbf4`,
  appName: "Veep Social",
  supportedChainIds: [1, 3, 4, 5, 42],
 });

const GlobalStyles = createGlobalStyle`
  .navbar {
    border-bottom: solid 1px rgba(255, 255, 255, .1) !important;
  }`;

 //  MetaMask only implementation contained to: <MetaMaskAuth onAddressChanged={metamaskAuth}  />   

export default function DappPage() {
  var loggedIn = false;
  var isNew = false;
  const search = window.location.search;
  const params = new URLSearchParams(search);
  const userId = params.get('userId');
  const sessionToken = params.get('sessionId');
  const web_register = params.get('web_register');
  var web_register2 = web_register ? web_register : "false";

  const { activate, deactivate, active, account, library, provider } = useWeb3React();

  const [fetchedNonce, setFetchedNonce] = useState("");
  const [userRegistered, setRegisteredUser] = useState("");
  const [loggedInUser, setLoggedInUser] = useState("");
  const [leftPage, setLeftPage] = useState(""); 
  const [loading, setLoading] = useState(""); 

  const onScreenLoad = () => {
    setLoading(false);
  }

  useEffect(() => {
      onScreenLoad();
  }, [])

  useEffect(() => {
      userDidRegister(setRegisteredUser);
  }, [userRegistered]);

  useEffect(() => {
      setUserLogin(setLoggedInUser);
  }, [loggedInUser]);

  useEffect(() => {
    setLeft(setLeftPage);
}, [leftPage]);

  const selectedAddress = () => {
    if (window.ethereum) {
      return window.ethereum.selectedAddress
    } else {
      return "none"
    }
  }
  
  return (
    <div>
      <GlobalStyles />
      <section className='jumbotron breadcumb no-bg'>
        <div className='mainbreadcumb'>
          <div className='container'>
            <div className='row'>
              <div className="row">
                {mainUI(active, account, leftPage)} 
              </div>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </div>
    )

    function displayLoadingSection() {
      if (loading != true) { return }
      return (<div> 
        <div className="spinner-container">
            <div className="loading-spinner">
            </div>
        </div>
      </div>)
    }

  // keep in mind the following wallet scenarios...
  //  wallet open = needs to open wallet / web3 browser
  //  wallet active = connected & in web3 browser
  //  wallet activate = is web3 browser, but needs to activate

  //the mainUI returned has following priority:
  //  - #1: if succesfully userRegistered, redirect to login page
  //  - #2: if sessionToken sent, but not connected yet, 
  //      - A. If user has not registered yet, & Not logged in, show login button (proceed to B. after login)
  //      - B. If user IS logged in, & we have ethereum window, & we are connected, show wallet connect options
  //        - B. 1. Else if user is logged in, ethereum window, BUT NOT connection, show wallet activate options
  //        - B. 2. Else if we are logged in, NO ETHEREUM window, show wallet Open options
  //  - #3: No session token: UI = sign message for new user
  //
  //  - if web_register parameter is false (Veep mobile-app modal wallet signin) redirect to page to copy sessionToken
  //  - if web_register is true or null (desktop browser / mobile browser) login user directly after sign message
  //
  function mainUI(active, userAddress, leftPage) {
    if (leftPage)
    {
      if (web_register2 === "true") {
        return (<div> {displayLoadingSection()} </div>)
      } else {
        return sessionToken ? ( <div> 
          You can now dismiss this page.  
        </div>) :  ( <div> 
          You can now dismiss this page.  <br /> <br />
          Back in the app, use the (key) button shown below to enter your login code. <br />
         <img alt="..." class="max-w-full rounded-lg shadow-lg" src="./img/veep-key.png" height="60px" width="72px"/>
        </div>)
      }
    } else if (userRegistered) 
    {
      if(web_register2 != "true") {
        window.location.href = `/loginRedirect?sessionId=${userRegistered}&new=${isNew}&web_register=${web_register2}`
      } else {
        window.location.href = `/home`
      }
    } else if (sessionToken) 
    {
      if (!userRegistered) 
      {
        if (loggedInUser) 
        { 
          if (active === false && window.ethereum) { //logged in, not connected, ethereum browser
            return (
              <div> {displayLoadingSection()}
                <span onClick={function () { activate(WalletConnect) }} className="btn-main lead">Connect (WalletConnect)</span> <br />
                <span onClick={function () { activate(Injected) }} className="btn-main lead">Connect (MetaMask)</span> <br />
                <span onClick={function () { activate(CoinbaseWallet) }} className="btn-main lead">Connect (Coinbase Wallet)</span> <br /> <br />
              </div>
            )
          } else if (active === true && window.ethereum) 
          { //logged in, web3 browser, connected
            return (<div> {displayLoadingSection()}
              Logged in as: @{Parse.User.current().getUsername()}. <br /> 
              Successfully connected with <Address userAddress={userAddress} />.  <br /> <br />
              
              <span onClick={function () { beginRegister(userAddress, setRegisteredUser, userId) }} className="btn-main lead">Login With Wallet</span> <br /> <br />
              <span onClick={function () { deactivate() }} className="btn-main lead">Disconnect</span> <br /> 
            </div>)
          } else { //logged in & not web3 browser
            return (<div> {displayLoadingSection()}
              Logged in as: @{Parse.User.current().getUsername()}. <br /> <br />
              <span onClick={function () { activate(WalletConnect) }} className="btn-main lead">Connect (WalletConnect)</span> <br />
              <span onClick={function () { openMetaMaskDeepLink() }} className="btn-main lead">Open (MetaMask)</span> <br />
              <span onClick={function () { openCoinBaseDeepLink() }} className="btn-main lead">Open (Coinbase Wallet)</span> <br />
              <br />
                <span onClick={function () { deactivate() }} className="btn-main lead">Disconnect</span> <br /> 
            </div>)
          }
        } else { //not logged in
          return (<div>
            <span onClick={function () { beginUserLogin(setLoggedInUser) }} className="btn-main lead">Login</span>
          </div> );
        }
      } else {
        return (<div> Error 5004 </div>);
      }
    } else { //no session token, user not registered, has not left page

      //if inside web3 browser, & not connected, allow user to connect wallet
      //if inside web3 browser & connected, show sign message
      //otherwise present deeplink into a web3 browser/wallet

      if (window.ethereum || active) 
      {
        if (active) 
        {         
          return ( <div> {displayLoadingSection()}
            Successfully connected with <Address userAddress={userAddress} />. < br /> <br /><br />
          <span onClick={() => beginRegister(userAddress, setRegisteredUser)} className="btn-main lead">Login With Wallet</span> <br /><br />
          <span onClick={function () { deactivate() }} className="btn-main lead">Disconnect</span> <br /> 
          </div> )
        } else {   //not active, but is web3 browser
          return (
            <div>
              <span onClick={function () { activate(WalletConnect) }} className="btn-main lead">Connect (WalletConnect)</span> <br />
              <span onClick={function () { activate(Injected) }} className="btn-main lead">Connect (MetaMask)</span> <br />
              <span onClick={function () { activate(CoinbaseWallet) }} className="btn-main lead">Connect (Coinbase Wallet)</span> <br /> <br />
            </div>
          )
        }
      } else {    //not active, non-web3 browser
        return (
          <div>   {displayLoadingSection()}
            <span onClick={function () { activate(WalletConnect) }} className="btn-main lead">Connect (WalletConnect)</span> <br />
            <span onClick={function () { openMetaMaskDeepLink() }} className="btn-main lead">Open (MetaMask)</span> <br />
            <span onClick={function () { openCoinBaseDeepLink() }} className="btn-main lead">Open (Coinbase Wallet)</span> <br />
          </div>
        );
      }
    }
  }

  function openCoinBaseDeepLink() {
    if (sessionToken) {
      window.location.href = `https://go.cb-w.com/dapp?cb_url=https%3A%2F%2Fveep.ar%2Fdapp%3FsessionId%3D${sessionToken}%26userId%3D${userId}%26web_register%3D${web_register2}`
    } else {
      window.location.href = `https://go.cb-w.com/dapp?cb_url=https%3A%2F%2Fveep.ar%2Fdapp%3Fweb_register%3D${web_register2}`
    }
    setLeftPage(true)
  }
  function openMetaMaskDeepLink() {
    if (web_register2 === "true") {
      window.location.href = `https://metamask.app.link/dapp/veep.ar/dapp?web_register=true`
    } else if (sessionToken) {
      window.location.href = `https://metamask.app.link/dapp/veep.ar/dapp?sessionId=${sessionToken}&userId=${userId}`
    } else {
      window.location.href = `https://metamask.app.link/dapp/veep.ar/dapp?web_register=false`
    }
    setLeftPage(true)
  }

  async function beginRegister(address, setRegisteredUser) {

    if (!address || !setRegisteredUser) { alert("There was an error, please try again. (Error 9011)"); return }
    //userId parameter is only sent when linking an account,
    //force logout to ensure we do not set a user object on the signature validation
    if (!userId) {
        Parse.User.logOut();
      isNew = true
    }


    // 10.02.22 - fix me: ios not sending web_register=false parameter,
    // force to false if not explicity sent as true
    if (web_register != "true") {
      web_register2 = false //10.02.22 - can set this back to true 
    }

    setLoading(true);

    await FetchNonce(address).then(function (fetchedNonce) {
      handleSignMessage(fetchedNonce, address).then(function (signature) {
        handleSignature(signature, address).then(function (signatureResponse) {
          if (!signatureResponse) { 
            alert("There was an error processing the signature, please try again."); 
            setLoading(false); 
            return 
          }

          // if registering on web, we can immediately login after receiving sesssionToken
          // otherwise, need to pass sessionToken back for client side login (redirect to loginRedirect page)
          // ** 10.02 - just log in the user either way, and then redirect to loginRedirect page
          if (web_register2 != "true") {  //10.02.22 - remove !web_register check here
            Parse.User.become(signatureResponse).then(function(success) {
              setRegisteredUser(signatureResponse);
            }, function(error) { 
              setRegisteredUser(signatureResponse);
            })
          } else {
            Parse.User.become(signatureResponse).then(function(success) {
              window.location.href = "/home";
            }, function(error) { 
              alert("There was an error, please try again.")
            })
          }
        }, function (handleSignatureError) { 
          setLoading(false);
        });
      }, function (handleSignMessageError) {
        setLoading(false);
      });
    });
  }

  async function handleSignMessage(nonce, publicAddress) {
    const web3 = new Web3(library.provider)
    const signingString = web3.utils.utf8ToHex("I am signing my one-time nonce for Veep.Social: " + nonce);

    return await web3.eth.personal.sign(signingString, publicAddress);
};

  async function handleSignMessage2(nonce, publicAddress) {
    const web3 = new Web3(Web3.givenProvider);
    const signingString = web3.utils.utf8ToHex("I am signing my one-time nonce for Veep.Social: " + nonce);

    return await web3.eth.personal.sign(signingString, publicAddress);
  };

  async function beginUserLogin(setLoggedInUser) {
    if (!loggedIn) {
      return await Parse.User.become(sessionToken).then(function (user) {
        loggedIn = true;
        isNew = false;
        return setLoggedInUser(Parse.User.current()); //(user)
      }, function (error) {
        loggedIn = true;
        isNew = false;
        return setLoggedInUser(Parse.User.current());
      });
    }
  }

  async function FetchNonce(address) {
    const nonceQuery = await Parse.Cloud.run("getNonceForPublicAddress", { "publicAddress": address }).then(function (nonce) {
      return nonce
    }, function (error) {
      Parse.User.logOut();
      return "Error 6001"
    });

    return nonceQuery
  }

  async function handleSignature(signature, publicAddress) {
    //when linking wallet, user object is set on this request (User logged in from session token)
    //when registering, user should not be logged in, user will not be found during server side validation
    const signatureQuery = await Parse.Cloud.run("validateSignature", { "publicAddress": publicAddress, "signature": signature, "userId": userId }).then(function (response) {
      return response
    }, function (error) {
      return "Error: 9003"
    });

    return signatureQuery
  }

  async function userDidRegister({ userRegistered }) {
    if (userRegistered) {
      setTimeout(function () { document.location.href = '/loginRedirect?sessionId=${userRegistered}&new=${isNew}'; }, 100);
      window.open('/loginRedirect?sessionId=${userRegistered}&new=${isNew}', '_blank');
    }
  }

  async function setUserLogin(setLoggedInUser) {

  }

  async function setLeft(leftPage) {

  }

  function Address({ userAddress }) {
    if (userAddress && userAddress.length > 0) {
      return (
        <span className="address">{userAddress.substring(0, 5)}…{userAddress.substring(userAddress.length - 4)}</span>
      );
    } else {
      return ( 'address-error')
    }
  }

}