import React, { useEffect, useState } from "react";
import { createGlobalStyle } from 'styled-components';

const GlobalStyles = createGlobalStyle`
  .navbar {
    border-bottom: solid 1px rgba(255, 255, 255, .1) !important;
  }
`;

const search = window.location.search;
const params = new URLSearchParams(search);
const success = params.get('success');

function mainUI() {
    if (success == "true") {
        return ( <div> Successfully signed post.  Please close this page and manually return back to the Veep app.<br /><br /><br /><br /></div> );
    } else {
        return ( <div> We encountered an error.  Please try again, or contact support.<br /> <br /><br /><br /> </div> )
    }
}

const SignerRedirect = () => {
    return (<div>
<GlobalStyles/>
  <section className='jumbotron breadcumb no-bg'>
    <div className='mainbreadcumb'>
      <div className='container'>
        <div className='row'>
        {mainUI()}
        </div>
      </div>
    </div>
  </section>
</div>
    );
}

export default SignerRedirect;