import React from "react";

function NFTComponent({imageUrl, title, contractAddress, tokenId}) {

  return (
    <div className="single-nft">
      <img className="single-nft-image" src={imageUrl} alt="" width="300" height="300"/>
      <div class="single-nft-image-caption">{title}</div>
    </div>
  );
}

export default NFTComponent;