import React from "react";
import PostComponent from "../components/postcomponent";
import Parse from 'parse';

var lastFetchName = "web_fetchTimeline"  

async function fetchPosts() {
    isFetching = true;
    var fetchName = "web_fetchTimeline";

    if (Parse.User.current()) {
        if (lastFetchName === "web_fetchTimelineFollowing") {
            fetchName = "web_fetchTimeline"
        } else {
            fetchName = "web_fetchTimelineFollowing"
        }
    } 
    const postQuery = await Parse.Cloud.run(fetchName, { "offset": offset }).then(function (response) {
        lastFetchName = fetchName
        var responseJson = JSON.stringify(response)
        isFetching = false
        return JSON.parse(responseJson);
    }, function (error) {
        isFetching = false
        return "error"
    });
    return postQuery
}

var isFetching = false;
var scrolled = false;
var offset = 0;
var atBottom = false;
var bottomId = 0;
var lastBottomId = 0;
var scrollPosition = 0;

var posts2 = null; //local var to hold copy of posts

class FeedComponent extends React.Component {

    componentWillMount() {
        const { posts, setPosts, setLoading } = this.props;
        posts2 = posts;

        window.addEventListener('scroll', function () {
            scrolled = true;

            //when user scrolls to bottom of feed, fetch the next offset of posts
            //combine old posts with newly fetched posts, 
            //scroll back to old scroll position
            if ((window.innerHeight + window.scrollY) >= document.body.offsetHeight - 240) {
                if (!atBottom) { bottomId++ } //only increment bottomId if we were not at bottom
                atBottom = true
                if (lastBottomId === bottomId) { return } //we've already handled this scroll
                if (!posts || !setPosts || !scrolled || isFetching) { return }

                scrolled = false
                offset += 20;
                setLoading(true);
                scrollPosition = window.pageYOffset;

                fetchPosts().then(function (response) {
                    setLoading(false);

                    var newPosts = Array.from(posts2);
                    newPosts = posts.concat(posts2,response);
                    posts2 = newPosts
                    setPosts(newPosts);

                    const lastPostId = String(response[0].objectId)
                    var postDiv = document.getElementById("post-" + lastPostId)
                    if (postDiv) {
                        postDiv.scrollIntoView();
                    }

                })

            } else {
                atBottom = false
                bottomId++;
            }
        });
    }

    render() {
        if (this.props.posts && this.props.posts.length > 1) {
            return (<div id="modify-table-wrap" className="modify-table-wrap2"> 
            {this.props.posts.map((post) => (
                <PostComponent
                    post={post}
                    postId={post.objectId}
                    username={post.username}
                    PostContent={post.PostContent}
                    image={post.postImageUrl}
                    avatarURL={post.avatarURL}
                    videoUrl={post.videoUrl}
                    isAuthorVerified={post.isVerified}
                    isAuthorENSVerified={post.isAuthorENSVerified}
                    authorENS={post.ensDisplayName}
                    isPostSigned={post.verifiedPost}
                    communitySlug={post.CommunitySlug}
                    nftImageUrl={post.nftImageUrl}
                    collectionName={post.collectionName}
                    token_id={post.tokenId}
                    createdAt={post.createdAt}
                    likeCount={post.Likes}
                    postCreator={post.userPointer}
                />
            ))} </div>)
        } else {
            return ""
        }
    }
}

export default FeedComponent;