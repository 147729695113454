import React from 'react';
import ReactDOM from 'react-dom';
import "./assets/animated.css";
import '../node_modules/font-awesome/css/font-awesome.min.css'; 
import '../node_modules/elegant-icons/style.css';
import '../node_modules/et-line/style.css';
import '../node_modules/bootstrap/dist/css/bootstrap.min.css';
import '../node_modules/bootstrap/dist/js/bootstrap.js';
import './assets/style.scss';
import './assets/output.css'
import App from './components/app';
import * as serviceWorker from './serviceWorker';
import HttpsRedirect from 'react-https-redirect';

//redux store
import { Provider } from 'react-redux'
import store from './store';

import ReactGA from 'react-ga';
ReactGA.initialize('UA-92226774-12'); // add your tracking id here.
ReactGA.pageview(window.location.pathname + window.location.search);

ReactDOM.render(
	<Provider store={store}>
		<HttpsRedirect>
			<App />
		</HttpsRedirect>
	</Provider>, 
	document.getElementById('root'));
// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();