import React from 'react';
import Particle from '../components/Particle';
import SliderMainParticle from '../components/SliderMainParticle';
import Footer from '../components/footer';
import { createGlobalStyle } from 'styled-components';
import Roadmap1 from '../components/Roadmap';
import Clock from "../components/Clock";
import AppImgBox from '../components/AppImgBox';
import TeamBox from '../components/TeamBox';
import StepBox from '../components/StepBox';
import FeaturesBox from '../components/FeaturesBox';
import HomeHero from '../components/HomeHero';
import Reveal from 'react-awesome-reveal';
import { keyframes } from "@emotion/react";
import ReactGA from 'react-ga';

const GlobalStyles = createGlobalStyle`
  header#myHeader .logo .d-block{
    display: none !important;
  }
  header#myHeader .logo .d-none{
    display: block !important;
  }
  .navbar .mainside a{
    background: #6ccfa5;
    &:hover{
      box-shadow: 2px 2px 20px 0px #6ccfa5;
    }
  }
  .item-dropdown{
    .dropdown{
      a{
        &:hover{
          background: #6ccfa5;
        }
      }
    }
  }
  p.lead{
    color: #a2a2a2;
  }
  .navbar .navbar-item .lines{
    border-bottom: 2px solid #6ccfa5;
  }
  .jumbotron.no-bg{
    height: 100vh;
    overflow: hidden;
    background-repeat: repeat;
    background-size: cover;
    background-position: bottom;
    background-repeat: no-repeat;
  }
  #tsparticles{
    top: 0;
  }
  .text-uppercase.color{
    color: #6ccfa5;
  }
  .de_count h3 {
    font-size: 36px;
    margin-bottom: 0px;
  }
  .de_count h5{
    font-size: 14px;
    font-weight: 500;
  }
  h2 {
    font-size: 30px;
  }
  .box-url{
    text-align: center;
    h4{
      font-size: 16px;
    }
  }
  .de_countdown{
    border: solid 2px #6ccfa5;
  }
  .author_list_pp, .author_list_pp i, 
  .nft_coll_pp i, .feature-box.style-3 i, 
  footer.footer-light #form_subscribe #btn-subscribe i, 
  #scroll-to-top div{
    background: #de46e1;
  }
  footer.footer-light .subfooter .social-icons span i{
    background: #403f83;
  }
  .author_list_pp:hover img{
    box-shadow: 0px 0px 0px 2px #6ccfa5;
  }
  .nft__item_action span{
    color: #6ccfa5;
  }
  .feature-box.style-3 i.wm{
    color: rgba(222, 70, 225, 0.97);
  }
  @media only screen and (max-width: 1199px) {
    .navbar{
      
    }
    .navbar .menu-line, .navbar .menu-line1, .navbar .menu-line2{
      background: #fff;
    }
    .item-dropdown .dropdown a{
      color: #fff !important;
    }
  }
`;

const fadeInUp = keyframes`
  0% {
    opacity: 0;
    -webkit-transform: translateY(40px);
    transform: translateY(40px);
  }
  100% {
    opacity: 1;
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
`;
const fadeIn = keyframes`
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
`;


const eventTrack = (category, action, label) => {
  ReactGA.event({
    category: category,
    action: action,
    label: label,
  })
}


document.addEventListener('DOMContentLoaded', function (event) {
  // array with texts to type in typewriter
  var dataText = ["Augmented Reality With Friends", "Create Your Own Virtual Space", "Live Augmented Reality"];

  // type one text in the typwriter
  // keeps calling itself until the text is finished
  function typeWriter(text, i, fnCallback) {
    if (i < (text.length)) {
      //document.querySelector("h1").innerHTML = text.substring(0, i + 1) + '<span aria-hidden="true"></span>';
      setTimeout(function () {
        typeWriter(text, i + 1, fnCallback)
      }, 100);
    }
    else if (typeof fnCallback == 'function') {
      setTimeout(fnCallback, 700);
    }
  }

  function StartTextAnimation(i) {
    if (typeof dataText[i] == 'undefined') {
      setTimeout(function () {
        StartTextAnimation(0);
      }, 20000);
    }
    if (typeof dataText[i] != 'undefined') {
      if (i < dataText[i].length - 1) {
        typeWriter(dataText[i], 0, function () {
          StartTextAnimation(i + 1);
        });
      }
    }
  }
  const pathname = window.location.pathname;
  if (pathname == '/') {
    StartTextAnimation(0);
  }

});


const Info = () => (

  <div>
  <GlobalStyles />
  <section className="jumbotron no-bg" style={{ backgroundImage: `url(${'./img/background/VeepCityBG.png'})` }}>
    <Particle />
      <SliderMainParticle />
    <div className='col-lg-12 text-center'>
      <div class="jumbotron jumbotron-fluid">
        <div class="container pt-40">
        <h1 class="header2">Augmented Reality With Friends</h1>
            <h3 class="header1-top">Veep.AR - Social AR </h3>
          <img src="./img/Veepman13.png"/>
          
        </div>
      </div>
    </div>
  </section>

    <Reveal className='onStep' keyframes={fadeInUp} delay={300} duration={900} triggerOnce>
      <section className='container'>
      <center>
          <div class="max-w-2xl mx-auto">
            <div
              class="p-4 w-full text-center bg-green-400 rounded-lg shadow-md sm:p-8">
              <h3 class="mb-2 text-3xl font-bold text-purple-700">🎉 Join The Party 🎉 </h3>
              <p class="mb-2 text-base text-purple-700 sm:text-lg">Get the Veep.AR app for iOS.  Use invite code: Party</p>
              <p class="mb-5 text-base text-purple-700 sm:text-lg"><a href="/home">Or view our web version in a web3 compatible browser</a></p>
              <center>
                <a href="https://apps.apple.com/us/app/veep-virtual-spaces/id1579238644" onClick={eventTrack.bind(this, "Home Page", "App Store Download pressed", "Download Pressed")}><img src="./img/WhiteAppBadge.svg" height="50%" width="30%" /></a>
              </center>
            </div>
          </div>
          <br />
        </center>
      </section>
    </Reveal>

    <section class="relative bg-blueGray-50">
      <div class="items-center flex flex-wrap">
      <a href="https://apps.apple.com/us/app/veep-virtual-spaces/id1579238644" onClick={eventTrack.bind(this, "Home Page", "App Store Download pressed", "Download Pressed")}>
    </a>
    </div>
  </section>

 <section class="relative pt-12 bg-blueGray-50">
      <div class="items-center flex flex-wrap">
        <div class="w-full md:w-4/12 ml-auto mr-auto px-4">
          <img alt="..." class="max-w-full rounded-lg shadow-lg" src="./img/AR-With-Friends.png" />
        </div>
        <div class="w-full md:w-5/12 ml-auto mr-auto px-4">
          <div class="md:pr-12">
            <h3 class="text-3xl font-semibold text-purple-700">Augmented Reality, With Friends</h3>
            <p class="mt-4 text-lg leading-relaxed text-white">
              Veep has the most advanced social augmented reality features!
            </p>
            <ul class="list-none mt-6">
              <li class="py-2">
                <div class="flex items-center">
                  <div>
                    <span class="text-xs font-semibold inline-block py-1 px-2 uppercase rounded-full text-pink-600 bg-pink-200 mr-3"><i class="fa fa-camera"></i></span>
                  </div>
                  <div>
                    <h4 class="text-blueGray-500">
                      Interact with friends from anywhere, in AR
                    </h4>
                  </div>
                </div>
              </li>
              <li class="py-2">
                <div class="flex items-center">
                  <div>
                    <span class="text-xs font-semibold inline-block py-1 px-2 uppercase rounded-full text-pink-600 bg-pink-200 mr-3"><i class="fa fa-user"></i></span>
                  </div>
                  <div>
                    <h4 class="text-blueGray-500">Connect with friends in a new way</h4>
                  </div>
                </div>
              </li>
              <li class="py-2">
                <div class="flex items-center">
                  <div>
                    <span class="text-xs font-semibold inline-block py-1 px-2 uppercase rounded-full text-pink-600 bg-pink-200 mr-3"><i class="fa fa-microphone"></i></span>
                  </div>
                  <div>
                    <h4 class="text-blueGray-500">Voice Chat Rooms in AR</h4>
                  </div>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </section>

    <section class="relative pt-12 bg-blueGray-50">
      <div class="items-center flex flex-wrap">
        <div class="w-full md:w-5/12 ml-auto mr-auto px-4">
          <div class="md:pr-12">
            <h3 class="text-3xl font-semibold text-purple-700">Virtual Spaces</h3>
            <p class="mt-4 text-lg leading-relaxed text-white">
            Create your own persistant virtual space and decorate it with objects, or visit other spaces to meet new friends! 
            </p>
            <ul class="list-none mt-6">
              <li class="py-2">
                <div class="flex items-center">
                  <div>
                    <span class="text-xs font-semibold inline-block py-1 px-2 uppercase rounded-full text-pink-600 bg-pink-200 mr-3"><i class="fa fa-home"></i></span>
                  </div>
                  <div>
                    <h4 class="text-blueGray-500">
                    Persistant Augmented Reality Rooms
                    </h4>
                  </div>
                </div>
              </li>
              <li class="py-2">
                <div class="flex items-center">
                  <div>
                    <span class="text-xs font-semibold inline-block py-1 px-2 uppercase rounded-full text-pink-600 bg-pink-200 mr-3"><i class="fa fa-user"></i></span>
                  </div>
                  <div>
                    <h4 class="text-blueGray-500">Live Multi-User Augmented Reality </h4>
                  </div>
                </div>
              </li>
              <li class="py-2">
                <div class="flex items-center">
                  <div>
                    <span class="text-xs font-semibold inline-block py-1 px-2 uppercase rounded-full text-pink-600 bg-pink-200 mr-3"><i class="fa fa-camera"></i></span>
                  </div>
                  <div>
                    <h4 class="text-blueGray-500">Add photos, videos, gifs, NFTs, or 3d objects in AR</h4>
                  </div>
                </div>
              </li>
            </ul>
          </div>
        </div>
        <div class="w-full md:w-4/12 ml-auto mr-auto px-4">
          <img alt="..." class="max-w-full rounded-lg shadow-lg" src="./img/app/App3.png" />
        </div>
      </div>
    </section>

    <section class="relative pt-12 bg-blueGray-50">
      <div class="items-center flex flex-wrap">
        <div class="w-full md:w-4/12 ml-auto mr-auto px-4">
          <img alt="..." class="max-w-full rounded-lg shadow-lg" src="./img/app/App1.png" />
        </div>
        <div class="w-full md:w-5/12 ml-auto mr-auto px-4">
          <div class="md:pr-12">
            <h3 class="text-3xl font-semibold text-purple-700">Live Audio Chat Rooms</h3>
            <p class="mt-4 text-lg leading-relaxed text-white">
              Enter the metaverse by joining a Veep Space. Augmented Reality chat rooms for you and up to 100+ other friends!
            </p>
            <ul class="list-none mt-6">
              <li class="py-2">
                <div class="flex items-center">
                  <div>
                    <span class="text-xs font-semibold inline-block py-1 px-2 uppercase rounded-full text-pink-600 bg-pink-200 mr-3"><i class="fa fa-camera"></i></span>
                  </div>
                  <div>
                    <h4 class="text-blueGray-500">
                      Live voice chat rooms in augmented reality
                    </h4>
                  </div>
                </div>
              </li>
              <li class="py-2">
                <div class="flex items-center">
                  <div>
                    <span class="text-xs font-semibold inline-block py-1 px-2 uppercase rounded-full text-pink-600 bg-pink-200 mr-3"><i class="fa fa-user"></i></span>
                  </div>
                  <div>
                    <h4 class="text-blueGray-500">Join rooms to talk about anything you like</h4>
                  </div>
                </div>
              </li>
              <li class="py-2">
                <div class="flex items-center">
                  <div>
                    <span class="text-xs font-semibold inline-block py-1 px-2 uppercase rounded-full text-pink-600 bg-pink-200 mr-3"><i class="fa fa-microphone"></i></span>
                  </div>
                  <div>
                    <h4 class="text-blueGray-500">Be a speaker, or a listener</h4>
                  </div>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </section>

    <section class="relative pt-12 bg-blueGray-50">
      <div class="items-center flex flex-wrap">
        <div class="w-full md:w-5/12 ml-auto mr-auto px-4">
          <div class="md:pr-12">
            <h3 class="text-3xl font-semibold text-purple-700">Web3 Social</h3>
            <p class="mt-4 text-lg leading-relaxed text-white">
              The first ever large scale AR Web3 &amp; NFT focused social media platform!
            </p>
            <ul class="list-none mt-6">
              <li class="py-2">
                <div class="flex items-center">
                  <div>
                    <span class="text-xs font-semibold inline-block py-1 px-2 uppercase rounded-full text-pink-600 bg-pink-200 mr-3"><i class="fa fa-star"></i></span>
                  </div>
                  <div>
                    <h4 class="text-blueGray-500">
                      Set your ENS display name and NFT avatar
                    </h4>
                  </div>
                </div>
              </li>
              <li class="py-2">
                <div class="flex items-center">
                  <div>
                    <span class="text-xs font-semibold inline-block py-1 px-2 uppercase rounded-full text-pink-600 bg-pink-200 mr-3"><i class="fa fa-star"></i></span>
                  </div>
                  <div>
                    <h4 class="text-blueGray-500">Post your NFT directly to the feed</h4>
                  </div>
                </div>
              </li>
              <li class="py-2">
                <div class="flex items-center">
                  <div>
                    <span class="text-xs font-semibold inline-block py-1 px-2 uppercase rounded-full text-pink-600 bg-pink-200 mr-3"><i class="fa fa-star"></i></span>
                  </div>
                  <div>
                    <h4 class="text-blueGray-500">Sign posts with your wallet</h4>
                  </div>
                </div>
              </li>
              <li class="py-2">
                <div class="flex items-center">
                  <div>
                    <span class="text-xs font-semibold inline-block py-1 px-2 uppercase rounded-full text-pink-600 bg-pink-200 mr-3"><i class="fa fa-star"></i></span>
                  </div>
                  <div>
                    <h4 class="text-blueGray-500">Wallet integration - SIWE</h4>
                  </div>
                </div>
              </li>
              <li class="py-2">
                <div class="flex items-center">
                  <div>
                    <span class="text-xs font-semibold inline-block py-1 px-2 uppercase rounded-full text-pink-600 bg-pink-200 mr-3"><i class="fa fa-star"></i></span>
                  </div>
                  <div>
                    <h4 class="text-blueGray-500">NFT Communuty Toplist</h4>
                  </div>
                </div>
              </li>
              <li class="py-2">
                <div class="flex items-center">
                  <div>
                    <span class="text-xs font-semibold inline-block py-1 px-2 uppercase rounded-full text-pink-600 bg-pink-200 mr-3"><i class="fa fa-star"></i></span>
                  </div>
                  <div>
                    <h4 class="text-blueGray-500">Comment on NFTs</h4>
                  </div>
                </div>
              </li>
            </ul>
          </div>
        </div>
        <div class="w-full md:w-4/12 ml-auto mr-auto px-4">
          <img alt="..." class="max-w-full rounded-lg shadow-lg" src="./img/app/App4.png" />
        </div>
      </div>
    </section>
    <Footer />
  </div>
);
export default Info;