import React, { useEffect, useState } from "react";
import Footer from '../components/footer';
import { createGlobalStyle } from 'styled-components';
import Parse from 'parse';
import Web3 from 'web3';
import { useWeb3React } from '@web3-react/core'
import { InjectedConnector } from "@web3-react/injected-connector";
import { WalletLinkConnector } from "@web3-react/walletlink-connector";
import { WalletConnectConnector } from "@web3-react/walletconnect-connector";

const Injected = new InjectedConnector({
  supportedChainIds: [1, 3, 4, 5, 42]
 });

 const WalletConnect = new WalletConnectConnector({
  rpcUrl: `https://mainnet.infura.io/v3/e16706f3b2d94592a10601fc2d6ccbf4`,
  bridge: "https://bridge.walletconnect.org",
  qrcode: true,
 });

 const CoinbaseWallet = new WalletLinkConnector({
  url: `https://mainnet.infura.io/v3/e16706f3b2d94592a10601fc2d6ccbf4`,
  appName: "Veep Social",
  supportedChainIds: [1, 3, 4, 5, 42],
 });

const GlobalStyles = createGlobalStyle`
  .navbar {
    border-bottom: solid 1px rgba(255, 255, 255, .1) !important;
  }`;


export default function DappPageSigner() {
  var loggedIn = false;
  var isNew = false;
  const search = window.location.search;
  const params = new URLSearchParams(search);
  const userId = params.get('userId');
  const sessionToken = params.get('sessionId');
  const postId = params.get('postId');

  const { activate, deactivate, active, account, library, provider } = useWeb3React();

  const [fetchedNonce, setFetchedNonce] = useState("");
  const [userRegistered, setRegisteredUser] = useState("");
  const [loggedInUser, setLoggedInUser] = useState("");
  const [leftPage, setLeftPage] = useState("");

  useEffect(() => {
      userDidRegister(setRegisteredUser);
  }, [userRegistered]);

  useEffect(() => {
      setUserLogin(setLoggedInUser);
  }, [loggedInUser]);

  useEffect(() => {
    setLeft(setLeftPage);
}, [leftPage]);

  const selectedAddress = () => {
    if (window.ethereum) {
      return window.ethereum.selectedAddress
    } else {
      return "none"
    }
  }
  
  return (
    <div>
      <GlobalStyles />
      <section className='jumbotron breadcumb no-bg'>
        <div className='mainbreadcumb'>
          <div className='container'>
            <div className='row'>
              <div className="row">
                {mainUI(active, account, leftPage, postId)} 
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
    )


  //the mainUI returned has following priority:
  //  - if succesfully userRegistered, redirect to login page
  //  - if sessionToken sent, but not connected yet, 

  //  - otherwise: UI = sign message for new user
  function mainUI(active, userAddress, leftPage, postId) {
      if (!postId) {
          return (<div> Error, invalid Veep Post to sign. </div>)
      }

      if (leftPage) {
          return sessionToken ? (<div>
              You can now dismiss this page.
          </div>) : (<div>
              You can now dismiss this page.  <br /> <br />
          </div>)
      } else if (userRegistered) {
          window.location.href = `/signerRedirect?success=${userRegistered}`
      } else if (sessionToken) {
          if (!userRegistered) {
              if (loggedInUser) {
                  if (active === false && window.ethereum) { //logged in, not connected, ethereum browser
                      return (
                          <div>
                              <span onClick={function () { activate(Injected) }} className="btn-main lead">Connect</span> <br />
                          </div>
                      )
                  } else if (active === true && window.ethereum) { //logged in, web3 browser, connected
                      return (<div>
                          Logged in as: @{Parse.User.current().getUsername()}. <br />
                          Successfully connected with <Address userAddress={userAddress} />.  <br /> <br />
                          Post Id: {postId}. <br /><br /> <br />

                          <span onClick={function () { beginSignMessage(userAddress, setRegisteredUser, userId) }} className="btn-main lead">Sign Message</span> <br /> <br />
                          <span onClick={function () { deactivate() }} className="btn-main lead">Disconnect</span> <br />
                      </div>)
                  } else { //logged in & not web3 browser
                      return (<div>
                          Logged in as: @{Parse.User.current().getUsername()}. <br /> <br />
                          <span onClick={function () { activate(WalletConnect) }} className="btn-main lead">Connect (WalletConnect)</span> <br />
                          <span onClick={function () { openMetaMaskDeepLink() }} className="btn-main lead">Open (MetaMask)</span> <br />
                          <span onClick={function () { openCoinBaseDeepLink() }} className="btn-main lead">Open (Coinbase Wallet)</span> <br />
                      </div>)
                  }
              } else { //not logged in
                  return (<div>
                      <span onClick={function () { beginLogin(setLoggedInUser) }} className="btn-main lead">Login</span>
                  </div>);
              }
          } else {
              return (<div> Error: 5022.  Please try again, or contact us if continue to have problems.</div>);
          }
      } else { //no session token, user not registered, has not left page
          return (
              <div>
                  You need to be logged in to your Veep profile to sign a new post.  Go back to the Veep app and try again.
              </div>);

      }
  }

  function openCoinBaseDeepLink() {
    if (sessionToken) {
      window.location.href = `https://go.cb-w.com/dapp?cb_url=https%3A%2F%2Fveep.space%2Fdapp%2Dsigner%3FsessionId%3D${sessionToken}%26userId%3D${userId}%26postId%3D${postId}`
    } 
    setLeftPage(true)
  }
  function openMetaMaskDeepLink() {
    if (sessionToken) {
      window.location.href = `https://metamask.app.link/dapp/veep.space/dapp-signer?sessionId=${sessionToken}&userId=${userId}&postId=${postId}`
    } 
    setLeftPage(true)
  }

  async function beginSignMessage(address, setRegisteredUser) {
    //if (!window.ethereum) { return }

    //userId parameter is only sent when linking an account,
    //force logout to ensure we do not set a user object on the signature validation
    if (!userId) {
      Parse.User.logOut();
      return
    }

    await FetchNonce(address).then(function (fetchedNonce) {
        FetchPostInfo(postId).then(function (message) {
            handleSignMessage(fetchedNonce, address, message).then(function (signature) {
                handleSignature(signature, address, postId).then(function (signatureResponse) {
                    setRegisteredUser(signatureResponse);
                });
            });
        });
    });
  }

  async function handleSignMessage(nonce, publicAddress, message) {
    const web3 = new Web3(library.provider)//(window.etherum);
    const signingString = web3.utils.utf8ToHex(message + ":" + nonce);

    return await web3.eth.personal.sign(signingString, publicAddress);
};

  async function beginLogin(setLoggedInUser) {
    if (!loggedIn) {
      return await Parse.User.become(sessionToken).then(function (user) {
        loggedIn = true;
        isNew = false;
        return setLoggedInUser(Parse.User.current()); //(user)
      }, function (error) {
        loggedIn = true;
        isNew = false;
        return setLoggedInUser(Parse.User.current());
      });
    }
  }

  async function FetchNonce(address) {
    const nonceQuery = await Parse.Cloud.run("getNonceForPublicAddress", { "publicAddress": address }).then(function (nonce) {
      return nonce
    }, function (error) {
      Parse.User.logOut();
      return "Error 6001"
    });

    return nonceQuery
  }

  async function FetchPostInfo(postId2) {
    const nonceQuery = await Parse.Cloud.run("getSigningMessageForPost", { "postId": postId2 }).then(function (message) {
      return message
    }, function (error) {
      Parse.User.logOut();
      return "Error 6026"
    });

    return nonceQuery
  }
  

  async function handleSignature(signature, publicAddress, postId) {
    //when linking wallet, user object is set on this request (User logged in from session token)
    //when registering, user should not be logged in, user will not be found during server side validation
    const signatureQuery = await Parse.Cloud.run("validatePostSignature", { "publicAddress": publicAddress, "signature": signature, "postId": postId }).then(function (response) {
      return response
    }, function (error) {
      return "Error: 9044"
    });

    return signatureQuery
  }

  async function userDidRegister({ userRegistered }) {
    if (userRegistered) {
      setTimeout(function () { document.location.href = '/signerRedirect?success=${userRegistered}'; }, 100);
      window.open('/signerRedirect?success=${userRegistered}', '_blank');
    }
  }

  async function setUserLogin(setLoggedInUser) {

  }

  async function setLeft(leftPage) {

  }

  function Address({ userAddress }) {
    if (userAddress && userAddress.length > 0) {
      return (
        <span className="address">{userAddress.substring(0, 5)}…{userAddress.substring(userAddress.length - 4)}</span>
      );
    } else {
      return ( 'address-error')
    }
  }

}