import React, { useEffect, useState } from "react";
import Footer from '../components/footer';
import PostComponent from "../components/postcomponent";
import { createGlobalStyle } from 'styled-components';
import Parse from 'parse';

const GlobalStyles = createGlobalStyle`
  .navbar {
    border-bottom: solid 1px rgba(255, 255, 255, .1) !important;
  }
`;

export default function Feed() {

    const [posts, setPosts] = useState([{},]);

    const onScreenLoad = () => {
        fetchPosts().then(function (response) {
            setPosts(response);
        })
    }

    useEffect(() => {
        onScreenLoad();
    }, [])

    async function fetchPosts() {
        const postQuery = await Parse.Cloud.run("web_fetchTimeline", { "id": "1" }).then(function (response) {
            var responseJson = JSON.stringify(response)
            return JSON.parse(responseJson);
        }, function (error) {
        });
        return postQuery
    }

    return (
        <div>
            <GlobalStyles />

            <section className='jumbotro2 breadcumb no-bg'>
                <div className='container'>
                    <div className="timeline">
                        {posts.map((post) => (
                            <PostComponent
                                post={post}
                                username={post.username}
                                PostContent={post.PostContent}
                                image={post.postImageUrl}
                                avatarURL={post.avatarURL}
                            />
                        ))}
                    </div>
                </div>
            </section>

            <Footer />
        </div>
    );
}