import React, { useEffect, useState } from "react";
import Footer from '../components/footer';
import { createGlobalStyle } from 'styled-components';
import Parse from 'parse';
import Avatar from "@material-ui/core/Avatar";
import NFTComponent from "../components/nftcomponent";

const GlobalStyles = createGlobalStyle`
  .navbar {
    border-bottom: solid 1px rgba(255, 255, 255, .1) !important;
  }
  .timeline {
    margin: 0 auto;
    max-width: 880px;
    padding: 20px;
  }
`;

export default function Edit() {
    const search = window.location.search;
    const params = new URLSearchParams(search);
    const userId = params.get('userId');
    const [user, setUser] = useState([{},]);
    const [ownedNft, setOwnedNft] = useState([{},]);
    const [ownedEns, setOwnedENS] = useState([{},]);
    const [selectedNFT, setSelectedNFT] = useState([{},]);
    const [selectedENS, setSelectedENS] = useState([{},]);
    const [editBio, setEditBio] = useState([{},]);

    const onScreenLoad = () => {
        fetchUser().then(function (userResponse) {
            setUser(userResponse);
            
            fetchNFT(userResponse.objectId).then(function (nftResponse) {
                setOwnedNft(nftResponse);

                fetchENS(userResponse.objectId).then(function (ensResponse) {
                    setOwnedENS(ensResponse);
                })
            })
        })
    }

    useEffect(() => {
        onScreenLoad();
    }, [])

    async function fetchUser() {
        var curUser = Parse.User.current() 
        if (curUser != null) {
            const userQuery = await Parse.Cloud.run("web_fetchUserInfoCloud", { "username": curUser.get("username"), "userId": "none"}).then(function (response) {
                var responseJson = JSON.stringify(response)
                return JSON.parse(responseJson);
            }, function (error) {
            });
            return userQuery
        }
    }

    async function fetchNFT(objectId) {
        const nftQuery = await Parse.Cloud.run("fetchNFTForUserId", { "userId": String(objectId), "type": "avatar" }).then(function (response) {
            var responseJson = JSON.stringify(response)
            return JSON.parse(responseJson);
        }, function (error) {
        });
        return nftQuery
    }

    async function fetchENS(objectId) {
        const nftQuery = await Parse.Cloud.run("fetchNFTForUserId", { "userId": String(objectId), "type": "ens" }).then(function (response) {
            var responseJson = JSON.stringify(response)
            return JSON.parse(responseJson);
        }, function (error) {
        });
        return nftQuery
    }

    function handleUserInfoSections() {
        var curUser = Parse.User.current() 
        if (curUser != null) {
            return (<div>
                <center> <Avatar className="post__avatar" alt={user.username} src={user.imageUrl} /> </center>
                <center><h4>@{user.username}</h4> <br /></center>

                    <form className="actions">
                        <h2> Bio </h2>
                        <input type='text' id="new-posts-container-textfield" className="new-posts-container-textfield" placeholder={user.aboutMe} onChange={event => setEditBio(event.currentTarget.value)} />
                        <br />
                        {handleSelectNFTSection()}
                        <br />
                        {handleSelectENSSection()}
                        <br /><br />
                        <span onClick={function () { doSaveAction() }} className="btn-main lead ml-5 mt-2">Save</span>
                    </form> 
            </div>)
        } else {
            return ( <div className="timeline"> <h3>You must be logged in to access this page.</h3></div>)
        }
    }

    async function doSaveAction() {
       
        doSaveNFTAvatar()

        if (typeof editBio !== 'undefined') {
            await Parse.Cloud.run("web_editProfile", { "bio": editBio }).then(function (response) {
                var responseJson = JSON.stringify(response)
                refreshAfterSave()
                return JSON.parse(responseJson);

            }, function (error) {
            });
        }
    }

    async function doSaveNFTAvatar() {
        if (typeof selectedNFT.asset_contract !== 'undefined') {
            await Parse.Cloud.run("saveNFTPhotoSelection", { "contractAddress": selectedNFT.asset_contract.address, "tokenId": selectedNFT.token_id }).then(function (response) {
                var responseJson = JSON.stringify(response)
                
                doSaveENS()
                return JSON.parse(responseJson);

            }, function (error) {
                alert("There was an erro setting your NFT avatar, please try again.")
            });
        } else {
            doSaveENS() 
        }
    }

    async function doSaveENS() {
        if (typeof selectedENS.asset_contract !== 'undefined') {
            await Parse.Cloud.run("saveENSDisplayNameSelection", { "contractAddress": selectedENS.asset_contract.address, "tokenId": selectedENS.token_id }).then(function (response) {
                var responseJson = JSON.stringify(response)
                refreshAfterSave()
                return JSON.parse(responseJson);

            }, function (error) {
                alert("There was an error setting your ENS name, please try again.")
            });
        }
    }

    async function refreshAfterSave() {
        setSelectedENS(null);
        setSelectedNFT(null);

        alert("Edit profile success");
        fetchUser().then(function (userResponse) {
            setUser(userResponse);
        })
    }

    function handleSelectNFTSection() {
        if (ownedNft) {
            if (ownedNft.constructor === Array) {
                return (<div> <br /> <br />
                    <h2> NFT Profile Photo </h2>
                    <div className="nft-section2">
                        <table> <tr>
                            {handleNFTTableSection()}
                        </tr></table></div> </div>)
            }
        }
    }

    function handleNFTTableSection() {
        if (ownedNft) { 
            if (ownedNft.constructor === Array) {
                return ownedNft.map((singleNFT) => (
                    <td> <NFTComponent
                        imageUrl={singleNFT.image_url}
                        title="ok"
                    /> 
                       <input type="radio" name="nft" onClick={ function() {
                         setSelectedNFT(singleNFT)
                       }} id="nft" /></td>
                ))
            }
        }
    }

    function handleSelectENSSection() {
        if (ownedEns) {
            if (ownedEns.constructor === Array) {
                return (<div> <br /> <br />
                    <h2> ENS Display Name </h2>
                    <div className="nft-section2">
                        <table> <tr>
                            {handleENSTableSection()}
                        </tr></table></div> </div>)
            }
        }
    }

    function handleENSTableSection() {
        if (ownedEns) { 
            if (ownedEns.constructor === Array) {
                return ownedEns.map((singleENS) => (
                    <td> <NFTComponent
                        imageUrl={singleENS.image_url}
                        title="ok"
                    /> 
                       <input type="radio" name="ens" onClick={ function() {
                         setSelectedENS(singleENS)
                       }} id="ens" /></td>
                ))
            }
        }
    }

    return (
        <div>
            <GlobalStyles />
            <div className='jumbotro2 pt-10 mt-20'> <div className="timeline"> 
                {handleUserInfoSections()}
            </div></div>
            <Footer />
        </div>
    );
}