import React from 'react';
import Footer from '../components/footer';
import { Link } from '@reach/router';
import { Accordion } from 'react-bootstrap';
import FeaturesBox from '../components/FAQBox';
import { createGlobalStyle } from 'styled-components';
import Roadmap from '../components/Roadmap';

const GlobalStyles = createGlobalStyle`
  .navbar {
    border-bottom: solid 1px rgba(255, 255, 255, .1) !important;
  }
`;

const PrivacyPage = () => (
<div>
<GlobalStyles/>

  <section className='jumbotron breadcumb no-bg'>
    <div className='mainbreadcumb'>
      <div className='container'>
        <div className='row'>
        Last Updated: 09/17/2021
<p>
Welcome to Veep!  Veep, Inc. is the owner of Veep (“Veep,” “we” and “us”) and we care about your privacy.  We have developed this Privacy Policy (this “Policy”) to describe the information we collect, how that information may be used, with whom it may be shared, and your choices, in connection with your use of our website, Veep mobile application, and any of our products and services that link to this Policy (collectively, our “services.”).    

Please read this Policy carefully before using our services.
 <br /><br />
Information We Collect
<br /><br />
We collect information in a few ways, so we have broken things down for you below to explain the types of information that we collect in each context.
Information You Provide to Us
<br /><br />
Your Account Information: <br /><br />
When you use our services, you may provide certain information directly to us.  For example, when you register for an account with Veep, we require that you provide us with a valid phone number, ask you to create a username (which could include your first and last name), and we collect your location information, gender, age, and birthdate.  Once you create a Veep account, you may upload photos of yourself, import a Bitmoji avatar, and add additional information to your profile such as your interests and information about your personality.  You may also link your Veep account with your other social media accounts, such as your Snapchat and Instagram accounts.   Please note that all information in your profile will be publicly visible to other users of our services. 
<br /><br />
Your Communication with Other Users:<br /><br />
When you communicate with other users, you may provide content and information such as in-app messages and video calls. This may require us to collect information from your device sensors, such as cameras, accelerometers, gyroscopes, compasses, microphones.  Other users will also see your profile information. Keep in mind that users that you communicate with may, in some cases, be able to save that content or copy it outside of Veep, so don’t send messages or share content that you wouldn’t want another user to save or share.
<br /><br />
Your Communication with Us:<br /><br />
We collect information when you communicate with us. The specific types of information we collect will depend on the forum in which you communicate with us.  For example, if you send us an email, we will collect your email address and the content of your email.  
<br /><br />
Phonebook:<br /><br />
You may upload information from your device’s phonebook. If another user uploads their phonebook, we may combine information from that user’s phonebook with other information we have collected about you.
<br /><br />
Social Media:<br /><br />
If you access the services through a third party social networking site or service, your list of “friends” from that site or service may be automatically imported to the services, and such “friends” may be able to access certain non-public information you have entered in your services user profile if they are also registered users of the services.
<br /><br />
Information We Automatically Collect<br /><br />
<br /><br />
As with most apps and websites, when you use our services we automatically receive and collect information about you and your device.  This information includes the following:
<br /><br />
Information about your device, such as the operating system, hardware, system version, the Internet Protocol (IP) address, device ID, and device language.
The specific actions that you take when you use our services, including but not limited to the pages and screens that you view or visit, search terms that you enter, how you interact with our services, and information about your interactions with other users.
The time, frequency, connection type, and duration of your use of our services.
Information about your wireless and mobile network connections, such as mobile phone number, service provider, and signal strength.
Location information, such as GPS information. 
Information regarding your interaction with email messages, for example, whether you opened, clicked on, or forwarded the email message.
Identifiers associated with cookies or other technologies that may uniquely identify your device or browser (as further described below); and
Pages you visited before or after navigating to our website.	
Cookies and Analytics
<br /><br />
As most websites and mobile applications do, we use analytics partners to collect certain information about how our services are used. We and our analytics partners use cookies, web beacons, and other technologies to receive and store certain types of information whenever you interact with our services through your computer or mobile device. A cookie is a small file containing a string of characters that is sent to your computer when you visit a website. When you visit the website again, the cookie allows that site to recognize your browser. Cookies may store unique identifiers, user preferences and other information. You can reset your browser to refuse all cookies or to indicate when a cookie is being sent. However, some website features or services may not function properly without cookies. We use cookies to analyze and improve our services, to store user preferences, track user trends, and provide relevant advertising to you. 
<br /><br />
By using our services, you consent to the processing of your information by our analytics partners in the manner and for the purposes set out above. Currently, our analytics partners include, without limitation, Amplitude. In order to ensure that you understand how our analytics partners handle your information and how they interact with our services, you should visit their privacy policies for more information, as Veep has no ability to control or monitor our analytics partners’ data collection or data use practices with respect to such information. 
<br /><br />
 At the present time our services do not respond to “Do Not Track” signals or similar mechanisms. 
 <br /><br />
How We Use Your Information 
<br /><br />
In general, we collect information from you so that we can provide our services, operate our business, and provide information that you request from us. This includes the following uses and purposes: 
<br /><br />
Create and administer your account.
Provide, operate, improve, maintain, and protect our services.
Provide you with technical and other support.
Send you services and company updates, marketing communication, service information, and other information about Veep and our services, and products and services of third parties, that we think you may be interested in.
Conduct research and analysis, and monitor and analyze trends and usage.
Enhance or improve user experience, our business, and our services, including the safety and security thereof.  
Send you push notifications through our app.
Personalize our services to you by, for example, suggesting friends or customizing content that you see. 
Communicate with you and respond to inquiries.
Operate our business and perform any other function that we believe in good faith is necessary to protect the security or proper functioning of our services.
As necessary to comply with any applicable law, regulation, subpoena, legal process, or governmental request.
Enforce contracts and applicable Terms of Service, including investigation of potential violations thereof.
Detect, prevent, or otherwise address fraud, security or technical issues.
Protect against harm to the rights, property or safety of Veep, our users, customers, or the public as required or permitted by law.

<br /> <br />

<br /><br />

How We Use Your Camera Data <br /> <br />
Veep does not send or store any of your live camera data. <br />
Veep uses your devices live camera data in order to visualize augmented reality scenes. <br />
Veep does not send or store any TrueDepth API data needed to process face filters and other face oriented camera effects <br />

<br /> <br />
How We Share Your Information
<br /><br />
Let’s get this out of the way:  we don’t rent or sell your personal information (any information that identifies you or is identifiable to you). 
<br /><br />
Like most companies, we share information in certain circumstances with third parties through operation of our services and our business.  Below we explain when that happens. 
<br /><br />
Other Users
<br /><br />
Your profile information is publicly visible to all users and it may be displayed to other users to facilitate user interaction within the services.  When you communicate with other users, you will provide information and content such as in-app messages and video calls – and to facilitate your interaction we will provide that content and information, as well as your profile information, to such other users. As noted earlier, users that you interact with may, in some cases, be able to save that content or copy it outside of the services, so don’t send messages or share content that you wouldn’t want another user to save or share.
<br /><br />
Your account privacy settings may allow you to limit the other users who can see certain information in your profile and/or what information in your profile is visible to others, such as by “blocking” or “unfriending” other users.   In certain cases, you may also be able to limit certain types of users or groups of users that can search for you. 
<br /><br />
Service Providers
<br /><br />
We use third parties to assist us with operating our business and providing our services, such as our technology vendors that help us maintain our services and partners that assist us with our marketing and communication.  These service providers will have access to your information in order to provide services to us.
<br /><br />
As Directed By You and With Your Consent
<br /><br />
Except as otherwise provided in this Policy, we share information with companies, organizations or individuals outside of Veep only at your direction or when we have your consent to do so.  
<br /><br />
Legal Proceedings
<br /><br />
We may share information with third party companies, organizations, governmental authorities, or individuals outside of Veep if we have a good-faith belief that access, use, preservation or disclosure of the information is reasonably necessary to:
<br /><br />
Meet any applicable law, regulation, subpoena, legal process or governmental request;
Enforce a contract, including but not limited to any applicable Terms of Service, including investigation of potential violations thereof;
Detect, prevent, or otherwise address fraud, security or technical issues; or
Protect against harm to the rights, property or safety of Veep, our users, customers or the public as required or permitted by law.

<br /><br />
Sale or Merger
<br /><br />
We may share information about you as part of a merger or acquisition. If Veep, Inc. is involved in a merger, asset sale, financing, liquidation or bankruptcy, or acquisition of all or some portion of our business to another company, we may share your information with that company before and after the transaction closes. In such a case, unless permitted or otherwise directed by applicable law, your information would remain subject to the terms of the applicable privacy policy in effect at the time of such transfer.
<br /><br />
Aggregate Information
<br /><br />
We may de-identify or aggregate information so that you are not identified as an individual, and use and provide that information to third parties without restriction. We may also provide aggregate usage information to third parties (or allow third parties to collect that information from you), who may use such information to understand how often and in what ways people use our services. However, we never disclose aggregate usage or de-identified information to a third party (or allow a third party to collect such information) in a manner that would identify you as an individual person. 
<br /><br />
Choices about Your Information
<br /><br />
Options are always nice to have, so we strive to provide you with choices with respect to your information.  Also remember, you can opt not to disclose certain information to us - but keep in mind some information may be needed to create a Veep account or to take advantage of some of our services and features.
<br /><br />
Modification and Access to Your Information
<br /><br />
You can access and modify most of your information through our app.  If you would like to modify or access additional information that is not available through the app, please contact us at support@veepapp.org.  We may ask you to verify your identify or provide additional information before we act on your request.  We may reject or deny requests for certain reasons, such as when we believe a request is fraudulent. 
<br /><br />
Deleting Your Information or Your Account
<br /><br />
You can delete most of your information through our app, and you can delete your account through our app.  You may also request deletion by contacting us at support@veepapp.org. Please note that in some cases we may be prohibited from deleting certain information (we will let you know in those situations, and some information may remain in our records after your deletion of such information from your account. We may use any aggregated or de-identified data derived from or incorporating your information after you update or delete it, but not in a manner that would identify you personally.  
  <br /><br />
Marketing 
<br /><br />
You may unsubscribe from Veep marketing communications at any time by following the “unsubscribe” link at the bottom of any such communication. Most promotional communications will also offer recipients choices about receiving additional messages.
<br /><br />
Other Users
<br /><br />
You may modify certain profile and other settings to control which users may interact with you, such as blocking certain users from contacting you. 
<br /><br />
International Transfer
<br /><br />
We are based in the United States, and our services are hosted there.  If you are using our services from another country, the laws governing our collection and use of information may be different from the laws of your country.  If you decide to use our services, or share your information with us, you are agreeing to be governed by the laws of the United States, and agree to the transfer of your information to the United States.
<br /><br />
Children
<br /><br />
Our Services are not directed at children under 13, and we do not knowingly collect information from children under 13. If you are under 13, please do not attempt to use our services or send any information about yourself to us.  If you are the parent of a child under the age of 13, and you believe he or she has shared information with us, please contact us at support@veepapp.org so that we can remove such information from our systems. 
<br /><br />
Residents of California<br /><br />
Please click here to read the California Privacy notice. <br /><br />
<br /><br />
Security of Your Information
<br /><br />
We use reasonable security measures, including measures designed to protect against unauthorized or unlawful processing and against accidental loss, destruction or damage to your information.  We also take certain measures to enhance the security of our services, however, since the Internet is not a 100% secure environment, we cannot guarantee, ensure, or warrant the security of any information you transmit to us. There is no guarantee that information may not be accessed, disclosed, altered, or destroyed by breach of any of our physical, technical, or managerial safeguards. It is your responsibility to protect the security of your login information.
<br /><br />
Retention of Information
<br /><br />
We retain information in accordance with applicable laws. The length of time we keep information depends on the type of information and whether we have an on-going business or legal need to retain it (for example, if you have an account with us, or to comply with applicable legal, tax or accounting requirements). Once we no longer have an on-going business or legal need to retain your information, we delete, aggregate, or de-identify it. 
<br /><br />
Third Party Websites and Services
<br /><br />
Our services may contain links to other websites and services operated by third parties, and may include social media features such as Snapchat and Instagram buttons or links. These third-party websites and services may collect information about you if you click on a link or visit those websites or services, and the social media sites may automatically record information about your browsing behavior every time you visit a website that has a social media button. Your interactions with these features and third parties are governed by the privacy policy of the third party, not by this Policy.  
<br /><br />
Changes to this Policy
<br /><br />
We may make changes to this Policy from time to time. When we do, we will post the updated version on this page. We encourage you to read this page each time that you use our services so that you will be aware of any changes, and your continued use of our services shall constitute your acceptance of any such changes. Changes to this Policy take effect from the date of publication, unless stated otherwise. 
<br /><br />
Contact Us
<br /><br />
If you have any comments, questions, concerns, or suggestions about Policy, or about our privacy practices in general, please contact us at support@veepapp.org.
       
            <br /><br />Contact Us
        
        <br /><br />If you have any comments, questions, concerns, or suggestions about these Terms, please contact us at support@veepapp.org.
        
        <br /><br /><br />Veep LLC 2015-2021</p>
        </div>
      </div>
    </div>
  </section>

  <Footer />
</div>

);
export default PrivacyPage;