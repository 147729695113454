import Reveal from 'react-awesome-reveal';
import { keyframes } from "@emotion/react";
import { Link } from '@reach/router';

const fadeInUp = keyframes`
  0% {
    opacity: 0;
    -webkit-transform: translateY(40px);
    transform: translateY(40px);
  }
  100% {
    opacity: 1;
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
`;
const fadeIn = keyframes`
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
`;

const StepBox = () => (
    <Reveal className='onStep' keyframes={fadeInUp} delay={300} duration={900} triggerOnce>
     

<section className='container'>

<div class="bg-green-400 p-4 mt-20 rounded">
  <div class="lg:grid lg:grid-flow-row-dense lg:grid-cols-2 lg:gap-12 lg:items-center">
      <div class="lg:col-start-2 md:pl-20">
          <h4 class="text-2xl leading-8 font-extrabold text-purple-900 dark:text-white tracking-tight sm:leading-9">
            Early Access
          </h4>
          <ul class="mt-10">
              <li>
                  <div class="flex">
                      <div class="flex-shrink-0">
                          <div class="flex items-center justify-center h-12 w-12 rounded-md bg-indigo-500 text-white">
                              <b>1</b>
                          </div>
                      </div>
                      <div class="ml-4">
                          <h5 class="text-lg leading-6 text-black dark:text-black font-bold">
                            Mint the NFT
                          </h5>
                          <p class="mt-2 text-base leading-6 text-black dark:text-black">
                          The intial drop date for our access codes will be announced in December. <a href="/early-access">Visit our <b>Early Access</b> page for pricing information.</a>
                          </p>
                      </div>
                  </div>
              </li>
              <li class="mt-10">
                  <div class="flex">
                      <div class="flex-shrink-0">
                          <div class="flex items-center justify-center h-12 w-12 rounded-md bg-indigo-500 text-white">
                              <b>2</b>
                          </div>
                      </div>
                      <div class="ml-4">
                          <h5 class="text-lg leading-6 text-black dark:text-black font-bold">
                            Connect Your Wallet
                          </h5>
                          <p class="mt-2 text-base leading-6 text-black dark:text-black">
                            Once the access codes have dropped, we will have a new web page available to verify your wallet with Veep.
                          </p>
                      </div>
                  </div>
              </li>
              <li class="mt-10">
                  <div class="flex">
                      <div class="flex-shrink-0">
                          <div class="flex items-center justify-center h-12 w-12 rounded-md bg-indigo-500 text-white">
                            <b>3</b>
                          </div>
                      </div>
                      <div class="ml-4">
                          <h5 class="text-lg leading-6 text-black dark:text-black font-bold">
                            Register Your Account
                          </h5>
                          <p class="mt-2 text-base leading-6 text-black dark:text-black">
                           Once you have verified you hold one of our access code NFT's in your wallet, you will be able to register to the Veep platform. 
                         </p>
                      </div>
                  </div>
              </li>
          </ul>
      </div>
      <div class="mt-10 -mx-4 md:-mx-12 relative lg:mt-0 lg:col-start-1">
          <img src="./img/InAppNFT-min.png" alt="illustration" class="relative mx-auto rounded w-1/2"/>
      </div>
  </div>
</div>
    </section>
    </Reveal>
);

export default StepBox;