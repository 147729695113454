import React, { useEffect, useState } from "react";
import Footer from '../components/footer';
import { createGlobalStyle } from 'styled-components';
import Parse from 'parse';
import PostComponent from "../components/postcomponent";

const GlobalStyles = createGlobalStyle`
  .navbar {
    border-bottom: solid 1px rgba(255, 255, 255, .1) !important;
  }
  .comments-section {
    margin: 0 auto;
    max-width: 880px;
    padding: 20px;
  }
`;

export default function Post() {
    const search = window.location.search;
    const params = new URLSearchParams(search);
    const postId = params.get('id');
    
    const [posts, setPosts] = useState([{},]);
    const [commentText, setCommentText] = useState([{}, ""]);
    const [comments, setComments] = useState([{},]);

    const onScreenLoad = () => {
        fetchPost().then(function (response) {
            setPosts(response);
        })

        fetchComments().then(function (response) {
            setComments(response);
        })
    }

    useEffect(() => {
        onScreenLoad();
    }, [])

    async function fetchPost() {
        const postQuery = await Parse.Cloud.run("web_fetchSinglePost", { "postId": postId }).then(function (response) {
            var responseJson = JSON.stringify(response)
            return JSON.parse(responseJson);
        }, function (error) {
        });
        return postQuery
    }

    async function fetchComments() {
        const postQuery = await Parse.Cloud.run("web_fetchSinglePostComments", { "postId": postId }).then(function (response) {
            var responseJson = JSON.stringify(response)
            return JSON.parse(responseJson);
        }, function (error) {
        });
        return postQuery
    }

    function handleCommentsSection() {
        if (comments.constructor === Array && comments[0].username != null) {
            return comments.map((post) => (
                <PostComponent
                    post={post}
                    username={post.username}
                    PostContent={post.PostContent}
                    image={post.postImageUrl}
                    avatarURL={post.avatarURL}
                    videoUrl={post.videoUrl}
                    isAuthorVerified={post.isVerified}
                    isAuthorENSVerified={post.isAuthorENSVerified}
                    authorENS={post.ensDisplayName}
                    isPostSigned={post.verifiedPost}
                />
            ))
        } else {
            return (<div className="post"> <br />
                <span className="inline"><span aria-hidden="true" className="icon-chat ml-5"></span> <h3>  &nbsp; &nbsp; No comments yet.</h3></span> <br /></div>)
        }
    }

    function handleSubmitCommentSection() {
        if (Parse.User.current() != null && posts.length > 0 && posts[0].username != null) {
            return (
               <div className="post pb-5 mt-10">
                    <form className="actions">
                        <input type='text' id="new-posts-container-textfield" className="new-posts-container-textfield" placeholder="  Veep your reply" onChange={event => setCommentText(event.currentTarget.value)} />
                        <span onClick={function () { handleSubmitPostAction() }} className="btn-main lead ml-5 mt-2">Submit</span>
                    </form>
                </div>)
        } else {
            return (<div className="post"> <br />
            <span onClick={function () { window.location.href="/dapp?web_register=true" }} className="btn-main lead ml-3 mt-2 mb-3">Connect Wallet</span> <h3>  &nbsp; &nbsp; Login or connect your wallet to submit a comment.</h3> <br /></div>)
        }
    }

    async function handleSubmitPostAction() {
        const postQuery = await Parse.Cloud.run("createNewCommentCloud", { "text": commentText, "postId": postId, "CommentType": "Normal" }).then(function (response) {
            document.getElementById('new-posts-container-textfield').value = '';
            fetchComments().then(function (response) {
                setComments(response);
            })
        }, function (error) {
            alert("We encountered an error while submitting your post, please try again.")
        });
        return postQuery
    }

    function handlePostsSection() {
        var post = posts[0];
        if (posts.length > 0 && posts[0].username != null) {
            return (<div>
                {<PostComponent
                    post={post}
                    postId={post.objectId}
                    username={post.username}
                    PostContent={post.PostContent}
                    image={post.postImageUrl}
                    avatarURL={post.avatarURL}
                    videoUrl={post.videoUrl}
                    isAuthorVerified={post.isVerified}
                    isAuthorENSVerified={post.isAuthorENSVerified}
                    authorENS={post.ensDisplayName}
                    isPostSigned={post.verifiedPost}
                    communitySlug={post.CommunitySlug}
                    nftImageUrl={post.nftImageUrl}
                    collectionName={post.collectionName}
                    token_id={post.tokenId}
                    createdAt={post.createdAt}
                    likeCount={post.Likes}
                    postCreator={post.userPointer}
                />}
            </div>)
        } else {
            return (<div>
                <div className="spinner-container">
                    <div className="loading-spinner">
                    </div>
                </div>
            </div>)
      
        }
    }

    return (
        <div>
            <GlobalStyles />
            <section className='jumbotro2 breadcumb no-bg mt-20'>
                <div className="timeline">
                  {handlePostsSection()}
                </div>
            </section>

            <section className='jumbotro2 breadcumb no-bg'>
                <div className="comments-section">
                {handleSubmitCommentSection()} 
                {handleCommentsSection()} 
                </div>
            </section>

            <Footer />
        </div>
    );
}