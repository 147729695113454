import React, { useEffect, useState } from "react";
import Footer from '../components/footer';
import { createGlobalStyle } from 'styled-components';
import Parse from 'parse';
import PostComponent from "../components/postcomponent";


const GlobalStyles = createGlobalStyle`
  .navbar {
    border-bottom: solid 1px rgba(255, 255, 255, .1) !important;
  }

.community-list-item {
    background-color: rgba(48, 48, 48, 0.2);
    border: 1px solid rgba(48, 48, 48, 0.2);
    border-radius: 15px;
    margin-bottom: 40px;
    width:60%;
  }
  .timeline {
    margin: 0 auto;
    max-width: 880px;
    padding: 20px;
  }
  .community-list-item-outer {
    display : flex;
    align-items : center;
  }
  .community-list-img {
      display: inline-block;
      *display: inline;
      vertical-align: top;
      zoom: 1;
  }
  .community-title {
    display: inline-block;
    *display: inline;
    vertical-align: middle;
    zoom: 1;
    height: 100%;
}

 
`;

export default function Toplist() {
    const search = window.location.search;
    const params = new URLSearchParams(search);
    const [posts, setPosts] = useState([{},]);

    const onScreenLoad = () => {
        fetchPosts().then(function (response) {
            setPosts(response);
        })
    }

    useEffect(() => {
        onScreenLoad();
    }, [])

    async function fetchPosts() {
        const postQuery = await Parse.Cloud.run("fetchCommunities", { "": "" }).then(function (response) {
            var responseJson = JSON.stringify(response)
            return JSON.parse(responseJson);
        }, function (error) {
        });
        return postQuery
    }

    function communityLinkFor(community) {
        var url = "/community?id=" + String(community.slug)
        return url
    }
    function handleTopCommunityListSection() {
        if (posts.constructor === Array && posts.length > 1) {
            return posts.map((community) => (
                <center>
                <div className="community-list-item">
                    <div className="community-list-item-outer">
                        <div className="community-list-img"><a href={communityLinkFor(community)}> <img src={community.imageURL} /> </a></div>
                        <div className="community-title ml-3"><a href={communityLinkFor(community)}>  <h3>{community.name}</h3> </a></div>
                        <div className="community-member-count ml-3">{community.memberCount} members </div>
                    </div></div>
                    </center>
            ))
        } else {
            return (<div><div>
                <div className="spinner-container">
                    <div className="loading-spinner">
                    </div>
                </div>
            </div></div>)
        }
    }

    return (
        <div>
            <GlobalStyles />
            <section className='jumbotro2 breadcumb no-bg mt-20'>
                <div className="timeline mt-10">
                    <center><span onClick={function () { window.location.href = "/submit-community" }} className="btn-main lead mt-2 mb-5">Submit New Community</span></center>
                    {handleTopCommunityListSection()}
                </div>
            </section>
            <Footer />
        </div>
    );
}