import React from 'react';
import Footer from '../components/footer';
import { createGlobalStyle } from 'styled-components';

const GlobalStyles = createGlobalStyle`
  .navbar {
    border-bottom: solid 1px rgba(255, 255, 255, .1) !important;
  }
`;


const search = window.location.search;
const params = new URLSearchParams(search);
const shareId = params.get('spaceId');
const shareLink = `veepspaces://?spaceId=${shareId}`;

const SpaceJoinPage = () => (
<div>
<GlobalStyles/>

  <section className='jumbotron breadcumb no-bg'>
    <div className='mainbreadcumb'>
      <div className='container'>
        <div className='row'><center>
        <span onClick={() => window.open(shareLink, "_self")} className="btn-main lead">Join Space</span> <br /> <br />

        <br /> <br />
        <a href="https://apps.apple.com/us/app/veep-virtual-spaces/id1579238644"><img src="./img/DownloadText.png" width="30%" /></a> <br />

        </center>
        </div>
      </div>
    </div>
  </section>

  <Footer />
</div>

);
export default SpaceJoinPage;