import Avatar from "@material-ui/core/Avatar";
import Video from "./video";
import React, { useEffect, useState } from "react";
import Parse from 'parse';
import UserComponent from "./usercomponent";
import Tooltip from "@material-ui/core/Tooltip";

function PostComponent({post, postId, username, PostContent, image, avatarURL, videoUrl, isAuthorVerified, isAuthorENSVerified, authorENS, isPostSigned, communitySlug, nftImageUrl, token_id, collectionName, createdAt, likeCount, postCreator }) {

  const onScreenLoad = () => {
    setLikes2(post.Likes);
  }

  useEffect(() => {
    onScreenLoad();
  }, [])

  function verifiedAuthorSection() {
    if (isAuthorVerified === true) {
      return ( <div><Tooltip title="This user is verified."><div><img src="./img/icons/VerifiedUser.png" height="16" width="16" /> </div></Tooltip></div>)
    }
  } 

  function verifiedAuthorENSSection() {
    if (isAuthorENSVerified === true) {
      return (<div><Tooltip title="This ENS display name is verified."><div><img src="./img/icons/VerifiedENS.png" height="16" width="16" /> </div></Tooltip></div>)
    }
  } 

  function signedPostSection() {
    if (isPostSigned === true) {
      return (<div><Tooltip title="This post has been signed and verified by the creator using their linked wallet."><div className="post__verifiedPost">  <div><img src="./img/icons/VerifiedENS.png" height="24" width="24" /> </div></div></Tooltip></div>)
    }
  }

  function handleCommentButtonSection() {
    var commentUrl = "/post?id=" + post.objectId
    if (post) {
      return (<div className="post__commentButton"> <div><a href={commentUrl}> <img src="./img/icons/CommentIcon.png" height="24" width="24" /> </a></div></div>)
    }
  }

  function handlePhotoVideoSection() {
    if (post.nftImageUrl != null) {
      var nftUrl = "/asset?contractAddress=" + post.contractAddress + "&tokenId=" + post.tokenId
      return (<div><a href={nftUrl}><img className="post__image" src={nftImageUrl} alt="" /> <i>{collectionName}</i></a></div>); 
    }

    if (image && videoUrl == null) {
      return (<img className="post__image" src={image} alt="" />);
    } 

    if (videoUrl) {
      return (<Video thumbUrl={image} videoUrl={videoUrl} />);
    }
  }

  //check to display ENS display name or username from account
  function getUsername() {
    if (isAuthorENSVerified === true) {
      return authorENS
    } else {
      if (postCreator) {
        if (postCreator.verifiedWallet === true) {
          return <Address userAddress={postCreator.publicAddress} />
        } else {
          return username
        }
      } else {
        return username
      }
    }
  }

  function handleUsernameSection() {
    var userUrl = "/user?userId=" + username
    return ( <a href={userUrl}><b> <div className="post__header__username">@{getUsername()} </div> &nbsp;</b></a> );
  }

  function handleCommunityDisplay() {
    var communityUrl ="/community?id=" + communitySlug
    if (communitySlug != null) {
      return ( <span className="community_section">&nbsp;<a href={communityUrl}><b>in @{communitySlug}</b></a> </span>)
    } else if (post) {
      var nftUrl = "asset?nftId=" + post.nftId
      if (post.nftId != null) {
        return ( <span className="community_section">&nbsp;<a href={nftUrl}><b>on @{post.nftId}</b></a> </span>)
      }
    }
  }

  function handlePostContentSection() {
    var postLinks = linkify2(String(PostContent))
    return postLinks
  }

  function linkify2(inputText) {
    var replacedText, replacePattern1, replacePattern2, replacePattern3;

    //URLs starting with http://, https://, or ftp://
    replacePattern1 = /(\b(https?|ftp):\/\/[-A-Z0-9+&@#\/%?=~_|!:,.;]*[-A-Z0-9+&@#\/%=~_|])/gim;
    replacedText = inputText.replace(replacePattern1, '<a href="$1" target="_blank">$1</a>');

    //URLs starting with "www." (without // before it, or it'd re-link the ones done above).
    replacePattern2 = /(^|[^\/])(www\.[\S]+(\b|$))/gim;
    replacedText = replacedText.replace(replacePattern2, '$1<a href="http://$2" target="_blank">$2</a>');

    //Change email addresses to mailto:: links.
    replacePattern3 = /(([a-zA-Z0-9\-\_\.])+@[a-zA-Z\_]+?(\.[a-zA-Z]{2,6})+)/gim;
    replacedText = replacedText.replace(replacePattern3, '<a href="mailto:$1">$1</a>');

    return replacedText;
  }

  function handleDateSection() {
    if (createdAt) {
      return ( <div className="post-date"> {time_ago(createdAt)} </div>)
    }
  }

  function Address({ userAddress }) {
    if (userAddress && userAddress.length > 0) {
      return (
        <span className="address">{userAddress.substring(0, 8)}…{userAddress.substring(userAddress.length - 4)}</span>
      );
    } else {
      return ( 'address-error')
    }
  }

  function time_ago(time) {
    switch (typeof time) {
      case 'number':
        break;
      case 'string':
        time = +new Date(time);
        break;
      case 'object':
        if (time.constructor === Date) time = time.getTime();
        break;
      default:
        time = +new Date();
    }
    var time_formats = [
      [60, 'seconds', 1], // 60
      [120, '1 minute ago', '1 minute from now'], // 60*2
      [3600, 'minutes', 60], // 60*60, 60
      [7200, '1 hour ago', '1 hour from now'], // 60*60*2
      [86400, 'hours', 3600], // 60*60*24, 60*60
      [172800, 'Yesterday', 'Tomorrow'], // 60*60*24*2
      [604800, 'days', 86400], // 60*60*24*7, 60*60*24
      [1209600, 'Last week', 'Next week'], // 60*60*24*7*4*2
      [2419200, 'weeks', 604800], // 60*60*24*7*4, 60*60*24*7
      [4838400, 'Last month', 'Next month'], // 60*60*24*7*4*2
      [29030400, 'months', 2419200], // 60*60*24*7*4*12, 60*60*24*7*4
      [58060800, 'Last year', 'Next year'], // 60*60*24*7*4*12*2
      [2903040000, 'years', 29030400], // 60*60*24*7*4*12*100, 60*60*24*7*4*12
      [5806080000, 'Last century', 'Next century'], // 60*60*24*7*4*12*100*2
      [58060800000, 'centuries', 2903040000] // 60*60*24*7*4*12*100*20, 60*60*24*7*4*12*100
    ];
    var seconds = (+new Date() - time) / 1000,
      token = 'ago',
      list_choice = 1;

    if (seconds == 0) {
      return 'Just now'
    }
    if (seconds < 0) {
      seconds = Math.abs(seconds);
      token = 'from now';
      list_choice = 2;
    }
    var i = 0,
      format;
    while (format = time_formats[i++])
      if (seconds < format[0]) {
        if (typeof format[2] == 'string')
          return format[list_choice];
        else
          return Math.floor(seconds / format[2]) + ' ' + format[1] + ' ' + token;
      }
    return time;
  }

  // like post

  var _likeCount = likeCount;
  const [liked, setLiked] = useState(false);
  const [likes2, setLikes2] = useState(likeCount);
  
  function handleLikeCount() {
    if(_likeCount === 0 || _likeCount === null || !_likeCount) {
      _likeCount = 0;
    }

    return ( <div className="post-like-count"> <span onClick={function () { doLikeAction() } }> <span aria-hidden="true" className={getLikeImage()}></span> {getLocalLikeCount()} </span></div>)
  }

  function getLocalLikeCount() {
    if (likes2 != null && likes2 >= 0) { //10.02 - likes bug, testing
      return likes2
    } else {
      return 0
    }
  }

  function getLikeImage() {
    if (liked === true) {
      return "icon-heart"
    } else {
      return "icon-heart"
    }
  }

  function doLikeAction() {
    if (!Parse.User.current()) { alert("You must login, or connect your wallet to like a post."); return }

    likePostQuery().then(function (response) {
      // a successful response like will return (unneeded) new like object
      if (response === '[object Object]') {
        _likeCount = 1 + getLocalLikeCount()

        setLikes2(_likeCount);
        setLiked(true)
      }
      if (response === "Unliked") {
        _likeCount = 1 - getLocalLikeCount()
        setLikes2(_likeCount);
        setLiked(false)
      }
      if (response === 'Error Like') {
        setLiked(false)
      }
    })
  }

  async function likePostQuery(id) {
    const userQuery = await Parse.Cloud.run("likeToggleCloud", { "PostId": post.objectId }).then(function (response) {
      return String(response);
    }, function (error) {
      return "Error Like";
    });
    return userQuery
  }

  function postId() {
    return "post-" + post.objectId
  }

  function handleLinkPreviewSection() {
    // return (<RNUrlPreview text={"any text to be parsed , https://www.youtube.com/watch?v=Kmiw4FYTg2U"}/>)
    // if (!post) { return }
    // var urlRegex = '/^(https?:\/\/[^/]+(\/[\w-]+)+)/'
    // const match = linkify2(String(PostContent)).match(urlRegex);
    // const url = match ? match[1] : null;
    // alert(linkify2(String(PostContent)))
    // if (url) {

    //   return <LinkPreview url={url} width='400px' />;
    // }
  }

  function handleDeleteButtonSection() {
    if (!post || !Parse.User.current()) { return }
    if (String(post.PostCreatorId) === String(Parse.User.current().id)) {
      return (<div> <span onClick={function () {  deleteActionQuery() }}><i className="fa fa-trash"> </i> </span></div>)
    }
  }

  async function deleteActionQuery() {
    var postId = post.objectId
    if (!postId || !Parse.User.current()) { return }

    const query = await Parse.Cloud.run("deleteTimelinePost", { "postId": postId, }).then(function (response) {
      if (response === 'deleted') {
        alert("The post has been deleted.")
      } else {
        alert("Error")
      }
      return String(response);
    }, function (error) {
      alert("Error")
      return "false";
    });
    return query
  }

  function mainUI() {
    if(!post) { return <div></div> }
    if (post.username) {
      return (<div>
         <div className="post"><div id={postId()} >
      <div className="post__header">
        <UserComponent avatarURL={post.avatarURL} user={post.userPointer}/> 
        {handleUsernameSection()} {verifiedAuthorSection()} {verifiedAuthorENSSection()} 
        {handleDateSection()} <br />
        {handleCommunityDisplay()}
      </div>
      <h4 className="post__text">
        {handlePhotoVideoSection()} <br /> 
        {signedPostSection()}
        <div id="content_section"><div dangerouslySetInnerHTML={{__html: handlePostContentSection()}}></div></div>
        <br />
        {handleLinkPreviewSection()} {handleDeleteButtonSection()}
        <br />
        {handleLikeCount()} {handleCommentButtonSection()} 
      </h4>
    </div>
      </div></div>)
    } else { return (<div></div>) }
  }

  return mainUI();
}

export default PostComponent;