import React from "react";
import Avatar from "@material-ui/core/Avatar";

function UserComponent({user, avatarURL}) {

    function mainUI() {
        if (!user || user === null) { return }
    
        if (user.isNFTAvatar === true && user) {
            return (<div className="nft-hexagon"><img className="post__avatar" src={String(avatarURL)} width="50" height="50" /> </div>)
        }
        if (user) {
            return (<div> <Avatar className="post__avatar" alt={user.username} src={String(avatarURL)} />  </div>)
        }

    }

    return (

        <div className="">
            {mainUI()}
        </div>
    );
}

export default UserComponent;