import React from 'react';
import { Router, Location, Redirect } from '@reach/router';
import { createGlobalStyle } from 'styled-components';
import ScrollToTopBtn from './menu/ScrollToTop';
import Header from './menu/header';
import FontAwesomeIcons from './pages/fontAwesomeIcons';
import Accordion from './pages/accordion';

import { Web3ReactProvider } from '@web3-react/core'
import { Web3Provider } from "@ethersproject/providers";
import { useWeb3React } from '@web3-react/core'

// Custom
import { initializeParse } from '@parse/react';
import Login from './pages/login';
import Edit from './pages/edit';
import Community from './pages/community';
import Top from './pages/top';
import SubmitCommunity from './pages/submit-community';
import Home from './pages/home';
import EarlyAccess from './pages/early-access';
import FAQ from './pages/faq';
import LoginRedirect from './pages/loginRedirect';
import SignerRedirect from './pages/signerRedirect';
import TermsPage from './pages/terms';
import Contact from './pages/contact';
import PrivacyPage from './pages/privacy';
import SpaceJoinPage from './pages/live';
import DappPage from './pages/dapp';
import DappPageSigner from './pages/dapp-signer';
import Feed from './pages/feed';
import Info from './pages/info';
import Post from './pages/post';
import User from './pages/user';
import Asset from './pages/asset';
import Search from './pages/search';
import TopUsers from './pages/topusers';

initializeParse(
  'https://parseapi.back4app.com',
  'QRe2N7fV5bMvbWRygdEvrPxMY948TUiSRtyN9DS2',
  'aaYH4CfiYorwaLpJIvTibPMoWPk39BK8nOg38mMk'
);

function getLibrary(provider) {
  return new Web3Provider(provider);
}

const reload = () => window.location.reload();
<Router path="/apple-app-site-association" onEnter={reload} />

const GlobalStyles = createGlobalStyle`
  
`;

export const ScrollTop = ({ children, location }) => {
  React.useEffect(() => window.scrollTo(0,0), [location])
  return children
}

const PosedRouter = ({ children }) => (
  <Location>
    {({ location }) => (
      <div id='routerhang'>
        <div key={location.key}>
          <Router location={location}>
            {children}
          </Router>
        </div>
      </div>
    )}
  </Location>
);

export default function App() {

  const { activate, deactivate, active, account, library, provider } = useWeb3React();

  return (
    <div className="wraper">
      <Web3ReactProvider getLibrary={getLibrary}>
        <GlobalStyles />
        <Header active={active} />
        <PosedRouter>
          <ScrollTop path="/">
            <Home exact path="/">
              <Redirect to="/home" />
            </Home>

            <EarlyAccess path="/early-access" />
            <FontAwesomeIcons path="/fontAwesomeIcons" />
            <Accordion path="/accordion" />
            <FAQ path="/faq" />
            <PrivacyPage path="/privacy" />
            <Contact path="/contact" />
            <TermsPage path="/terms" />
            <SpaceJoinPage path="/live" />
            <DappPage path="/dapp" />
            <DappPageSigner path="/dapp-signer" />
            <SignerRedirect path="/signerRedirect" />
            <LoginRedirect path="/loginRedirect" />
            <Feed path="/feed" />
            <Info path="/info" />
            <Post path="/post" />
            <User path="/user" />
            <Login path="/login" />
            <Edit path="/edit" />
            <Community path="/community" />
            <Top path="/top" />
            <SubmitCommunity path="/submit-community" />
            <Asset path="/asset" />
            <Search path="/search" />
            <TopUsers path="/topusers" />
          </ScrollTop>
        </PosedRouter>
        <ScrollToTopBtn />
      </Web3ReactProvider>

    </div>
)
}