import React from 'react';

const EarlyAccess= () => (
  
  <section className='container6'>
    
        <section className='container'><center>
        <img src="./img/EARLY.png" height="80%" width="80%" /> <br />

      <img src="./img/rooms-triple-phone.png" height="10%" width="80%" />
      </center>
    </section>

    <a href="https://apps.apple.com/us/app/veep-virtual-spaces/id1579238644"><img src="./img/AppPromoInviteCode.png" height="1000px;" width="100%" /></a>

    <section className='container'><center>
    <a href="https://apps.apple.com/us/app/veep-virtual-spaces/id1579238644"><img src="./img/WhiteAppBadge.svg" height="50%" width="50%" /></a>
    </center>
    </section>

    <section className='container'><center>
    <h1 class="header2">Invite Code: Veep2022</h1>
    </center>
    </section>

    <section className='container'><center>
      <h3 class="header2">Learn More</h3>
      <h1 class="header2">Our Plans For The Metaverse</h1>
    </center>
      <center>
        <div class="container3">

          <center><span onClick={() => window.open("https://medium.com/@veepsocial/veep-social-the-introduction-cfb8ed3e66ca")} className="btn-main lead">Read More</span></center>
        </div>
      </center>
    </section>


    <section className='container'>
      <img src="./img/veep-redesign.png" height="60%" width="100%" />
    </section>


    </section>

    
  
);
export default EarlyAccess;